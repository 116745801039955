<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired && !isDisabled"
    :classes="[
      'input-block--number',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <input
      :id="name"
      v-model="value"
      ref="input"
      type="number"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :required="isRequired"
      :min="min"
      :max="max"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('enter', value)">

    <template #alerts>
      <slot></slot>
    </template>

  </InputBlock>
</template>

<script>
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputNumber',
  mixins: [
    InputMixin,
  ],
  components: {
    InputBlock,
  },
  props: {
    min: {
      type: String,
      default: () => '',
    },
    max: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue ? +this.modelValue : 0;
      },
      set(value) {
        this.$emit('update:modelValue', +value);
      },
    },
  },
};
</script>
