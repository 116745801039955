<template>

  <Loadbar></Loadbar>

  <transition name="sidebar">
    <Sidebar></Sidebar>
  </transition>

  <div class="main" id="main">

    <div class="view" id="view">
      <router-view v-slot="{ Component }">
        <component :is="Component"/>
      </router-view>
    </div>

    <Footer></Footer>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import KeyCombo from '@/services/keycombo/KeyComboService';
import Loadbar from '@/components/layout/loadbar/Loadbar.vue';
import Sidebar from '@/components/layout/sidebar/Sidebar.vue';
import Footer from '@/components/layout/footer/Footer.vue';

export default {
  name: 'AppMain',
  components: {
    Loadbar,
    Sidebar,
    Footer,
  },
  async created() {
    document.onkeydown = (e) => (KeyCombo.onkeydown(e));
    document.onkeyup = (e) => (KeyCombo.onkeyup(e));
    await this.$store.dispatch('getWorld');
    await this.$store.dispatch('getSelf');
  },
  computed: {
    ...mapGetters(['userIsLogged']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.pageTitle
          ? `${this.$t(`route.${to.meta.pageTitle}`)} - OpenODS Index`
          : 'OpenODS Index';
      },
    },
  },
};
</script>
