<template>
  <table class="table" :class="classes">

    <!-- actions and filters -->
    <TableActions
      :actions="actions"
      v-model:action="action"
      :selected="selected"
      :filters="filters"
      :filter="filter"
    ></TableActions>
    <!-- end actions and filters -->

    <!-- table head -->
    <thead v-if="rows.length">
      <tr>
        <th
          v-for="(column, c) in columns"
          :key="c"
          :class="column.class">
          <input v-if="actions.length && c === 0" type="checkbox" v-model="selectAll">
          {{column.head}}
        </th>
        <th class="rowaction" v-if="rowactions.length"></th>
      </tr>
    </thead>
    <!-- end table head -->

    <tbody :class="{'is-loading': isLoading, 'is-unready': !isReady}">
      <tr
        v-for="(row, r) in rows" :key="r"
        @mouseDown.middle="onMiddleClick(row)"
        :class="[ rowclass(row), {'row-click': this.onrowclick }]">
        <td
          @click="debounce(onRowClick, row)"
          v-for="(column, c) in columns"
          :key="c"
          :data-label="`${column.head}:`"
          :class="column.class">
          <!-- primary chip component -->
          <component
            v-if="column.component && column.class === 'table__chip'"
            :is="column.component"
            v-bind="column.componentAttrs(row)"
            :class="{'chip--selected': rowIsSelected(row)}"
            @thumbClick.stop="debounce(onChipThumbClick, row)"
            @bodyClick.stop="debounce(onChipBodyClick, row)"
          ></component>
          <!-- end chip component -->
          <!-- regular dynamic component -->
          <component
            v-if="column.component && column.class !== 'table__chip'"
            :is="column.component"
            v-bind="column.componentAttrs(row)"
          ></component>
          <!-- end regular dynamic component -->
          <!-- link component -->
          <router-link
            v-if="column.route"
            :to="column.route(row)"
            :title="column.value(row)">
            {{column.value(row)}}
          </router-link>
          <!-- end link component -->
          <!-- regular text -->
          <span
            v-if="isSimpleColumn(column)"
            :title="`${column.head}: ${column.value(row)}`">
            {{column.value(row)}}
          </span>
          <!-- end regular text -->
        </td>
        <td class="rowaction" v-if="rowactions.length">
          <TableRowActions :actions="rowactions" :row="row"></TableRowActions>
        </td>
      </tr>
    </tbody>

    <!-- empty message -->
    <tfoot v-if="rows.length === 0">
      <tr>
        <td>{{$t('common.no_table_results')}}</td>
      </tr>
    </tfoot>
    <!-- end empty message -->

    <TablePagination
      v-if="pagination.count"
      @setOffset="setOffset"
      v-model:limit="actualLimit"
      :pagination="pagination"
    ></TablePagination>

    <!-- loading spinner -->
    <div v-show="isLoading" class="loading">
      <div class="loading__wrap">
        <Spinner class="loading__spinner" :stroke="4" :size="32"></Spinner>
      </div>
    </div>
    <!-- end loading spinner -->

    <teleport to="body">
      <ModalConfirm
        v-if="isModalConfirmOpen"
        :title="modal.title"
        :confirm="modal.confirm"
        :isDanger="true"
        @close="checkModalConfirmation">
          {{modal.body}}
        </ModalConfirm>
    </teleport>

  </table>
</template>

<script>
import debounce from 'debounce';
import TableActions from '@/components/layout/table/TableActions.vue';
import TablePagination from '@/components/layout/table/TablePagination.vue';
import TableRowActions from '@/components/layout/table/TableRowActions.vue';

export default {
  name: 'Table',
  emits: ['update:offset', 'update:limit', 'update:filter'],
  components: {
    TableActions,
    TablePagination,
    TableRowActions,
  },
  data() {
    return {
      selected: [],
      action: '',
      isModalConfirmOpen: false,
      modal: {},
    };
  },
  props: {
    rows: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    columns: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    pagination: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    onrowclick: {
      type: Function,
      required: false,
      default: null,
    },
    onchipthumbclick: {
      type: Function,
      required: false,
      default: null,
    },
    onchipbodyclick: {
      type: Function,
      required: false,
      default: null,
    },
    onrowmidclick: {
      type: Function,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReady: {
      type: Boolean,
      required: false,
      default: true,
    },
    actions: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    rowactions: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    rowisselected: {
      type: [Function, null],
      required: false,
      default: null,
    },
    filters: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    filter: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    classes: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    rowclass: {
      type: Function,
      required: false,
      default: () => '',
    },
    limit: {
      type: Number,
      required: false,
      default: +process.env.VUE_APP_API_LIMIT_DEFAULT,
    },
  },
  computed: {
    actualLimit: {
      get() {
        return this.limit;
      },
      set(value) {
        this.$emit('update:limit', +value);
      },
    },
    selectAll: {
      get() {
        const ids = this.rowsId;
        return this.rows
          ? (
            this.selected.length === this.rows.length
            && this.selected.every((id) => ids.includes(id))
          ) : false;
      },
      set(value) {
        const selected = [];
        if (value) {
          this.rows.forEach((row) => { selected.push(row.uuid); });
        }
        this.selected = selected;
      },
    },
    rowsId() {
      return this.rows ? this.rows.map((i) => i.uuid) : [];
    },
  },
  methods: {
    debounce: debounce((func, arg) => { func(arg); }, 10),
    onRowClick(row) {
      // Left click handler
      if (this.onrowclick) this.onrowclick(row);
    },
    onChipThumbClick(row) {
      // Left click handler on chip thumbnail
      if (this.onchipthumbclick) {
        this.onchipthumbclick(row);
      } else if (this.actions?.length) {
        this.addOrRemoveRowSelection(row);
      } else if (this.onrowclick) this.onrowclick(row);
    },
    onChipBodyClick(row) {
      // Left click handler on chip body
      if (this.onchipbodyclick) {
        this.onchipbodyclick(row);
      } else if (this.onrowclick) this.onrowclick(row);
    },
    onMiddleClick(row) {
      // Mouse wheel click handler
      if (this.onrowmidclick) this.onrowmidclick(row);
      else if (this.onrowclick) this.onrowclick(row);
    },
    isSimpleColumn(column) {
      return !column.component && !column.route;
    },
    setOffset(offset) {
      this.$emit('update:offset', offset);
    },
    preActionExecution() {
      // Check if action exists and if it has a modal dialog
      if (!this.action) return;
      const action = this.actions.find((a) => a.v === this.action);
      if (!action) return;

      if (action.modal) {
        this.modal = { ...action.modal };
        this.isModalConfirmOpen = true;
      } else {
        this.actionExecution();
      }
    },
    checkModalConfirmation(isConfirmed) {
      if (isConfirmed) {
        this.actionExecution();
      } else {
        this.postActionExecution();
      }
    },
    actionExecution() {
      if (!this.action) return;
      const action = this.actions.find((a) => a.v === this.action);
      if (!action) return;
      action.function([...this.selected]);
      this.postActionExecution();
    },
    postActionExecution() {
      setTimeout(() => {
        this.action = '';
        this.isModalConfirmOpen = false;
        this.modal = {};
      }, 10);
    },
    removeInvisibleCheckboxes() {
      const ids = this.rowsId;
      this.selected = this.selected.filter((id) => ids.includes(id));
    },
    rowIsSelected(row) {
      return this.rowisselected
        ? this.rowisselected(row)
        : this.selected.includes(row.uuid);
    },
    addOrRemoveRowSelection(row) {
      if (this.rowIsSelected(row)) {
        this.selected = this.selected.filter((id) => id !== row.uuid);
      } else {
        this.selected.push(row.uuid);
      }
    },
  },
  watch: {
    action: 'preActionExecution',
    rows: 'removeInvisibleCheckboxes',
  },
};
</script>

<style lang="scss">
.table {
  border: 0;
  font-size: 14px;
  position: relative;
  margin-bottom: 20px;
  th {
    border: 0;
    padding: 8px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: var(--text-color-light);
    border-bottom: 1px solid var(--border-color-dark);
  }
  td {
    border: 0;
    padding: 8px;
    vertical-align: top;
    border-bottom: 1px solid var(--border-color-dark);
    &.checkbox {
      padding: 0;
      line-height: 0;
      width: 0;
      height: 0;
      position: absolute;
      pointer-events: none;
      margin: 0;
      border: 0;
      input[type=checkbox] {
        padding: 0;
        line-height: 0;
        width: 0;
        height: 0;
        position: absolute;
        pointer-events: none;
        margin: 0;
        border: 0;
      }
    }
  }
  tr {
    border: 0;
  }
  thead {
    border: 0;
  }
  tbody {
    border: 0;
    transition: opacity 200ms ease-in-out;
    &.is-unready {
      display: none;
    }
    &.is-loading {
      opacity: 0.4;
      pointer-events: none;
    }
    td {
    }
    tr {
      &:last-child td {
        box-shadow: 0 -1px var(--input-border-color-light),
                    inset 0 -1px var(--input-border-color-light);
      }
      &:hover {
        background-color: var(--background-color-light);
      }
      .primary {
        font-weight: 500;
      }
    }
  }

  a {
    color: inherit;
    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }
  }

  .row-click {
    cursor: pointer;
  }

  .rowaction {
    width: 10px;
  }

  .loading {
    // caption-side: bottom;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__wrap {
      flex: 0 0 auto;
    }
    &__spinner {
      display: block;
      margin: 5px auto;
    }
  }

  .number {
    text-align: right;
    font-variant: tabular-nums;
  }

  .pre {
    white-space: pre;
  }

  .chip--row {
    width: 100%;
  }
}

@media screen and (max-width: 779px) {
  .table {
    display: block;
    thead {
      display: none;
    }
    tbody {
      display: block;
    }
    caption {
      display: block;
      &.table__head {
        display: none;
      }
    }
    tr {
      display: block;
    }
    td {
      display: none;
      &.table__chip {
        display: block;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .pagination__data {
      flex-wrap: wrap;
    }
    .pagination__results {
      padding-left: 8px;
    }
  }
}
</style>
