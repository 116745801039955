<template>
  <Chip
    v-if="user.id"
    :thumbnail="getThumbnail"
    :letter="getLetter"
    :color="user.color"
    :classes="[...classes, 'chip--user']">

    <div class="chip__info chip__info--primary">
      {{getName}}
    </div>
    <div class="chip__info chip__info--secondary">
      {{getUsername}}
    </div>
  </Chip>
</template>

<script>
import Chip from '@/components/chips/Chip.vue';
import ChipMixin from '@/components/chips/ChipMixin';
import ImageService from '@/services/image/ImageService';

export default {
  name: 'UserChip',
  mixins: [
    ChipMixin,
  ],
  components: {
    Chip,
  },
  props: {
    user: {
      // type: Object,
      required: true,
      default: () => ({}),
    },
    classes: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  computed: {
    getThumbnail() {
      if (!this.user?.image) return '';
      return ImageService.imageUrl(this.user.image);
    },
    getLetter() {
      return this.user.email.charAt(0).toUpperCase();
    },
    getName() {
      return this.user.is_staff
        ? 'IndexOpenODS Team'
        : this.user.email;
    },
    getUsername() {
      return this.user.is_staff ? 'admin user' : 'regular user';
    },
  },
};
</script>
