<template>
  <section class="resdocuments">
    <div class="resdocuments__wrap">
      <BlockSubtitle modal="documents">{{$t('common.documents')}}</BlockSubtitle>

      <InputText
        name="search_document"
        v-model.trim="filteredName"
        v-show="results.length"
        :placeholder="$t('common.search_documents')"></InputText>

      <div class="resdocuments__list">
        <a
          v-for="(doc, i) in paginatedResults(results)"
          :key="i"
          :href="fs.fileUrl(doc.file)"
          target="_blank"
          class="resdocuments__item">

          <div class="resdocuments__icon">
            <img
              :src="`/img/icons/files/${getDocumentExtension(doc)}.png`"
              :alt="getDocumentExtension(doc)">
          </div>
          <div class="resdocuments__name">
            <div><strong>{{doc.entity}} - {{$t(`challenges.${doc.challenge}`)}}</strong></div>
            {{getDocumentName(doc)}}
          </div>
        </a>
      </div>

      <div v-show="paginatedResults(results).length == 0">
        <Warn type="info">{{$t('error.no_files_to_show')}}</Warn>
      </div>

      <div class="resdocuments__pagination" v-if="getPagesLength(results).length > 1">
        <div class="resdocuments__page resdocuments__page--prev" v-if="showPrevius(results)" @click="current_page -= 1">
          {{$t('common.previous')}}
        </div>
        <div
          v-for="(page, i) in getPagesLength(results)"
          :key="i"
          class="resdocuments__page"
          :class="{'is-current': page === current_page }"
          @click="current_page = page">{{page}}</div>
        <div class="resdocuments__page resdocuments__page--next" v-if="showNext(results)" @click="current_page += 1">
          {{$t('common.next')}}
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import FileService from '@/services/file/FileService';

export default {
  name: 'ResultsDocumentsComponent',
  data() {
    return {
      current_page: 1,
      page_size: 10,
      filteredName: '',
      fs: FileService,
    };
  },
  props: {
    results: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  methods: {
    filterResults(results) {
      if (!this.filteredName) return results;
      const filtered = this.filteredName.toLowerCase();
      return results
        .filter((c) => (
          c.file.toLowerCase().indexOf(filtered) !== -1
          || c.entity.toLowerCase().indexOf(filtered) !== -1
        ));
    },
    paginatedResults(results) {
      return this.filterResults(results)
        .slice((this.current_page - 1) * this.page_size, this.current_page * this.page_size);
    },
    getPagesLength(results) {
      const totalPages = Math.ceil(this.filterResults(results).length / this.page_size);
      const pages = Array(totalPages).fill().map((_, idx) => idx + 1);
      const currentIdx = pages.findIndex((n) => n === this.current_page);
      if (currentIdx < 2) {
        return pages.slice(0, 3);
      }
      if (currentIdx > pages.length - 3) {
        return pages.slice(pages.length - 3);
      }
      return pages.slice(currentIdx - 1, currentIdx + 2);
    },
    showPrevius() {
      return this.current_page > 1;
    },
    showNext(results) {
      const totalPages = Math.ceil(this.filterResults(results).length / this.page_size);
      return this.current_page < totalPages;
    },
    getDocumentName(doc) {
      const splittedName = doc.file.split('/');
      return splittedName[splittedName.length - 1];
    },
    getDocumentExtension(doc) {
      const splittedName = doc.file.split('.');
      return splittedName[splittedName.length - 1].toLowerCase();
    },
  },
};
</script>

<style lang="scss">
.resdocuments {
  &__item {
    display: flex;
    margin-bottom: 16px;
  }
  &__icon {
    flex: 0 0 60px;
    img {
      width: 44px;
      height: auto;
    }
  }
  &__name {
    flex: 1 1 calc(100% - 60px);
    color: var(--text-color-default);
  }
  &__pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  &__page {
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-left: 6px;
    background: var(--background-color-darker);
    text-align: center;
    font-size: 12px;
    cursor: pointer;

    &--next {
      width: auto;
      padding: 0 5px;
    }
    &--prev {
      width: auto;
      padding: 0 5px;
    }

    &.is-current {
      background: var(--background-color-inverted);
      color: var(--text-color-inverted);
    }
  }
}
</style>
