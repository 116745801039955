<template>
  <div class="input-shares">
    <div v-for="(share, i) in value" :key="i">
      <InputText
        :name="`shareurl-${i}`"
        :label="isEditing(i) ? $t('common.url') : ''"
        v-model="value[i].url"
        :runValidations="true"
        :isDisabled="!isEditing(i)"
        :validators="urlValidators">
        <span v-if="value[i].url && !urlValidators.every((v) => v(value[i].url))">
          {{$t('error.invalid_url')}}
        </span>
        <template #link>
          <a v-if="!isEditing(i)" @click="editThisRow(i)">
            {{$t('common.edit')}}
          </a>
          <a v-if="isEditing(i)" v-show="value[i].url && urlValidators.every((v) => v(value[i].url))" @click="closeThisRow(i)">
            {{$t('common.save')}}
          </a>
          <a class="input-block__remove__link" @click.stop="removeShare(i)">
            {{$t('common.remove')}}
          </a>
        </template>
      </InputText>
    </div>

    <Button v-show="!isDisabled" :isDisabled="!canAddShare" @onclick="addShare()">
      <span v-if="value.length > 0">{{$t('common.add_another')}}</span>
      <span v-else>{{$t('common.add')}}</span>
    </Button>
  </div>
</template>

<script>
import Check from '@/services/check/Check';
import InputMixin from '../InputMixin';

export default {
  name: 'InputShares',
  mixins: [
    InputMixin,
  ],
  data() {
    return {
      urlValidators: [Check.isValidUrl],
      editing: [],
    };
  },
  computed: {
    value: {
      get() {
        return Array.isArray(this.modelValue)
          ? this.modelValue
          : [];
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    canAddShare() {
      return !this.value.some((i) => i.url === '' || !this.urlValidators.every((v) => v(i.url)));
    },
  },
  methods: {
    addShare() {
      this.value = [...this.value, { type: 'URL', url: '' }];
      this.editing = [this.value.length];
    },
    removeShare(i) {
      this.value.splice(i, 1);
    },
    isEditing(idx) {
      return this.editing.indexOf(idx) > -1;
    },
    editThisRow(idx) {
      this.editing.push(idx);
    },
    closeThisRow(idx) {
      this.editing = this.editing.filter((id) => id !== idx);
    },
  },
};
</script>

<style lang="scss">
.input-shares {
  .input-block {
    padding-top: 0;
    padding-bottom: 0;
    input[type=text] {
      padding-right: 50px;
    }
    &.is-disabled {
      .input-block__headers {
        margin-bottom: -22px;
        position: relative;
        z-index: 9;
      }
    }
  }
  .btn {
    margin-top: 20px;
  }
}
</style>
