/**
 * FileService class
 */
export default class {
  /**
   * Convert file to base64
   */
  static loadFiles(files) {
    const promises = [];
    files.forEach((file) => {
      promises.push(
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            resolve({
              name: file.name,
              size: file.size,
              file: reader.result,
            });
          };
        }),
      );
    });
    return Promise.all(promises);
  }

  /**
   * Convert file url to convenience format
   */
  static fileUrl(file) {
    if (!file) return null;
    if (file.startsWith('/')) {
      return `${process.env.VUE_APP_API_URL}${file}`;
    }
    return `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_MEDIA_ROOT}${file}`;
  }
}
