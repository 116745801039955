<template>
  <div class="form-block form-block--row">
    <div class="form-block__cell form-block__cell--label">
      {{label}}
    </div>
    <div class="form-block__cell form-block__cell--value">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormRow',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.form-block {
  &--row {
    display: flex;
    padding: 12px 2px;
  }
  &__cell {
    flex: 1 1 100%;
    &--label {
      flex: 1 1 200px;
      font-weight: 500;
    }
    &--value {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
