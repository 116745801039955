module.exports = [
  {
    path: '/auth',
    name: 'login',
    component: () => import('@/components/auth/Login.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import('@/components/auth/Register.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/auth/recover-password',
    name: 'recover-password',
    component: () => import('@/components/auth/RecoverPassword.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/auth/reset-password',
    name: 'reset-password',
    component: () => import('@/components/auth/ResetPassword.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/auth/activate',
    name: 'activate-account',
    component: () => import('@/components/auth/ActivateAccount.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: () => import('@/components/auth/Logout.vue'),
    meta: {
      isPublic: true,
    },
  },
];
