<template>
  <InputSelect
    :name="name"
    :classes="classes"
    :label="label"
    :help="help"
    :isDisabled="isDisabled"
    :isRequired="isRequired"
    :focusOnMount="focusOnMount"
    v-model="value"
    :options="entityTypesList"
    ref="field"
  >
  <template #link>
      <slot name="link"></slot>
  </template>
  </InputSelect>
</template>

<script>
import { mapGetters } from 'vuex';
import Generator from '@/services/generator/GeneratorService';
import InputMixin from '../InputMixin';

export default {
  name: 'InputEntityType',
  mixins: [
    InputMixin,
  ],
  computed: {
    ...mapGetters(['entityTypes']),
    entityTypesList() {
      return this.entityTypes.map((d) => ({
        v: d,
        n: this.$t(`entityType.${d}`),
      })).sort(Generator.nameSort);
    },
  },
  methods: {
    maybeDeleteValue() {
      this.$refs.field.maybeDeleteValue();
    },
  },
};
</script>
