<template>
  <div class="form-tip">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormTip',
};
</script>

<style>
.form-tip {
  color: #7f8290;
  font-size: 12px;
  margin: 16px 0;
  line-height: 1.3;
}
</style>
