<template>
  <caption class="table__head">

    <div v-if="filters.length && !selected.length" class="table__filters">
      <component
        v-for="(f, i) in filters"
        :key="i"
        :is="f.component"
        :name="f.property"
        :label="f.label"
        :classes="['input-block--narrow']"
        v-model="currentFilter[f.property]"
        v-bind="f.componentProps"
      ></component>
    </div>

    <div v-if="actions.length && selected.length" class="table__actions">
      <InputSelect
        name="actions"
        :classes="['input-block--narrow']"
        :label="$t('common.actions')"
        v-model="currentAction"
        :options="actions"
      ></InputSelect>
    </div>

  </caption>
</template>

<script>
export default {
  name: 'TableActions',
  emits: ['update:filter', 'update:action'],
  props: {
    actions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    action: {
      type: String,
      required: false,
      default: '',
    },
    selected: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    filters: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    filter: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    currentFilter: {
      get() {
        return this.filter;
      },
      set(value) {
        this.$emit('update:filter', value);
      },
    },
    currentAction: {
      get() {
        return this.action;
      },
      set(value) {
        this.$emit('update:action', value);
      },
    },
  },
  watch: {
    currentFilter: {
      handler(value) {
        this.$emit('update:filter', value);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.table__head {
  .input-block {
    padding: 0;
    input[type=text],
    input[type=number],
    input[type=email],
    input[type=password],
    select {
      padding: 3px 10px;
    }
  }

  .input-block--name-number {
    max-width: 140px;
  }
  .input-block--name-year {
    max-width: 90px;
  }
  .input-block--name-month {
    max-width: 140px;
  }
  .input-block--name-status {
    max-width: 140px;
  }
  .input-block--name-is_paid {
    max-width: 140px;
  }
  .input-block--name-is_purchase {
    max-width: 120px;
  }
}

.table__actions {
  display: flex;
}
.table__filters {
  display: flex;
  flex-wrap: wrap;
}
</style>
