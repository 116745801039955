import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import VueGtag from "vue-gtag";

import Bar from "@/components/layout/bar/Bar.vue";
import Page from "@/components/layout/page/Page.vue";
import Tabs from "@/components/layout/tabs/Tabs.vue";
import Warn from "@/components/layout/warn/Warn.vue";
import Modal from "@/components/layout/modal/Modal.vue";
import ModalConfirm from "@/components/modals/confirm/ModalConfirm.vue";
import ModalChallenge from "@/components/modals/challenge/ModalChallenge.vue";
import Overlay from "@/components/layout/overlay/Overlay.vue";
import Table from "@/components/layout/table/Table.vue";
import Button from "@/components/layout/button/Button.vue";
import Spinner from "@/components/layout/spinner/Spinner.vue";
import DateTime from "@/components/layout/datetime/DateTime.vue";
import Currency from "@/components/layout/currency/Currency.vue";
import Status from "@/components/layout/status/Status.vue";
import UserChip from "@/components/chips/user/UserChip.vue";
import CompanyChip from "@/components/chips/company/CompanyChip.vue";
import ChallengeChip from "@/components/chips/challenge/ChallengeChip.vue";
import BlockTitle from "@/components/layout/block-title/BlockTitle.vue";
import BlockSubtitle from "@/components/layout/block-title/BlockSubtitle.vue";
import BlockSection from "@/components/layout/block-title/BlockSection.vue";
import InputText from "@/components/inputs/text/InputText.vue";
import InputNumber from "@/components/inputs/number/InputNumber.vue";
import InputEmail from "@/components/inputs/email/InputEmail.vue";
import InputPassword from "@/components/inputs/password/InputPassword.vue";
import InputTextArea from "@/components/inputs/textarea/InputTextArea.vue";
import InputCheckbox from "@/components/inputs/checkbox/InputCheckbox.vue";
import InputSelect from "@/components/inputs/select/InputSelect.vue";
import InputEntity from "@/components/inputs/entity/InputEntity.vue";
import InputYear from "@/components/inputs/year/InputYear.vue";
import InputFile from "@/components/inputs/file/InputFile.vue";
import InputImage from "@/components/inputs/image/InputImage.vue";
import InputMonth from "@/components/inputs/month/InputMonth.vue";
import InputDate from "@/components/inputs/date/InputDate.vue";
import InputShares from "@/components/inputs/shares/InputShares.vue";
import InputContacts from "@/components/inputs/contacts/InputContacts.vue";
import InputIndicators from "@/components/inputs/indicators/InputIndicators.vue";
import InputNetworks from "@/components/inputs/networks/InputNetworks.vue";
import InputCertifications from "@/components/inputs/certifications/InputCertifications.vue";
import InputGoals from "@/components/inputs/goals/InputGoals.vue";
import InputLocalGoals from "@/components/inputs/goals/InputLocalGoals.vue";
import Form from "@/components/forms/Form.vue";
import FormTip from "@/components/forms/tip/FormTip.vue";
import FormRow from "@/components/forms/row/FormRow.vue";
import FormTitle from "@/components/forms/title/FormTitle.vue";

// Styles
import "./styles/_index.scss";

// Dayjs
require("dayjs/locale/es");

// App
const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
        params: {
          pageTrackerScreenviewEnabled: true,
          anonymize_ip: true
        }
      }
    },
    router
  );

// Global components
app.component("Bar", Bar);
app.component("Page", Page);
app.component("Tabs", Tabs);
app.component("Warn", Warn);
app.component("Modal", Modal);
app.component("ModalConfirm", ModalConfirm);
app.component("ModalChallenge", ModalChallenge);
app.component("Overlay", Overlay);
app.component("Table", Table);
app.component("Button", Button);
app.component("Spinner", Spinner);
app.component("DateTime", DateTime);
app.component("Currency", Currency);
app.component("Status", Status);
app.component("UserChip", UserChip);
app.component("CompanyChip", CompanyChip);
app.component("ChallengeChip", ChallengeChip);
app.component("BlockTitle", BlockTitle);
app.component("BlockSubtitle", BlockSubtitle);
app.component("BlockSection", BlockSection);
app.component("InputText", InputText);
app.component("InputNumber", InputNumber);
app.component("InputEmail", InputEmail);
app.component("InputPassword", InputPassword);
app.component("InputTextArea", InputTextArea);
app.component("InputCheckbox", InputCheckbox);
app.component("InputSelect", InputSelect);
app.component("InputEntity", InputEntity);
app.component("InputYear", InputYear);
app.component("InputFile", InputFile);
app.component("InputImage", InputImage);
app.component("InputMonth", InputMonth);
app.component("InputDate", InputDate);
app.component("InputShares", InputShares);
app.component("InputContacts", InputContacts);
app.component("InputIndicators", InputIndicators);
app.component("InputNetworks", InputNetworks);
app.component("InputCertifications", InputCertifications);
app.component("InputGoals", InputGoals);
app.component("InputLocalGoals", InputLocalGoals);
app.component("Form", Form);
app.component("FormTip", FormTip);
app.component("FormRow", FormRow);
app.component("FormTitle", FormTitle);

// Mount app
app.mount("#app");
