<template>
  <time class="datetime" :datetime="value" :title="dateFormat(value, 'llll')">
    {{value ? dateFormat(value, format) : '-'}}
  </time>
</template>

<script>
import Parser from '@/services/parser/Parser';

export default {
  name: 'DateTime',
  props: {
    value: {
      required: true,
    },
    format: {
      type: String,
      required: false,
      default: 'lll',
    },
  },
  methods: {
    dateFormat: Parser.date,
  },
};
</script>
