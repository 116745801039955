<template>
  <Modal :classes="classes" @overlayClick="cancelModal">
    <template #header>{{title}}</template>
    <slot></slot>
    <template #footer>
      <Button @onclick="cancelModal">
        {{cancel || this.$t('common.cancel')}}
      </Button>
      <Button :classes="btnClasses" @onclick="confirmModal" :isDisabled="!isValid">
        {{confirm || this.$t('common.confirm')}}
      </Button>
    </template>
  </Modal>
</template>

<script>
import KeyCombo from '@/services/keycombo/KeyComboService';
import ModalMixin from '../ModalMixin';

export default {
  name: 'ModalConfirm',
  mixins: [
    ModalMixin,
  ],
  props: {
    title: {
      type: String,
    },
    confirm: {
      type: String,
    },
    cancel: {
      type: String,
    },
    isDanger: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    btnClasses() {
      return this.isDanger ? ['btn--danger'] : ['btn--accent'];
    },
  },
  mounted() {
    KeyCombo.newCombo('ModalConfirm.Esc', [27], this.onEscape);
  },
  methods: {
    cancelModal() {
      this.$emit('close', false);
    },
    confirmModal() {
      this.$emit('close', true);
    },
    onEscape() {
      this.$emit('close', false);
    },
  },
  beforeUnmount() {
    KeyCombo.removeCombo('ModalConfirm.Esc');
  },
};
</script>
