<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired && !isDisabled"
    :isInvalid="!isValid"
    :classes="[
      'input-block--email',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <input
      :id="name"
      v-model="value"
      ref="input"
      type="email"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :required="isRequired"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('enter', value)">

    <template #alerts>
      <div v-if="!isValid">{{$t('error.invalid_email')}}</div>
      <slot></slot>
    </template>

  </InputBlock>
</template>

<script>
import Check from '@/services/check/Check';
import InputProps from '../InputProps';
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputEmail',
  mixins: [
    InputMixin,
  ],
  components: {
    InputBlock,
  },
  props: InputProps.props,
  computed: {
    isValid() {
      return (this.isRequired || this.runValidations) && this.value
        ? Check.isValidEmail(this.value)
        : true;
    },
  },
};
</script>
