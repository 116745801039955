import API from '@/services/api/ApiService';
import HttpService from '@/services/http/HttpService';

export default {
  state: {
    self: {},
    accessToken: null,
  },
  mutations: {
    /**
     * Store current user
     */
    setSelf(state, obj) {
      state.self = obj;
    },

    /**
     * Update store tokens
     */
    updateToken(state, access) {
      state.accessToken = access;
      API.setToken(access);
    },
  },
  actions: {
    /**
     * Get user self profile
     */
    getSelf() {
      return new Promise((resolve, reject) => {
        HttpService.get(API.url('self'), API.getHeaders())
          .then((response) => {
            this.commit('setSelf', response.data);
            resolve(response.data);
          }).catch((response) => {
            reject(response);
          });
      });
    },

    /**
     * Login user and obtain tokens
     */
    login(commit, { password, email }) {
      return new Promise((resolve, reject) => {
        API.clearToken();
        HttpService.post(API.url('login'), { password, email }, { withCredentials: true })
          .then((response) => {
            this.commit('updateToken', response.data.access);
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },

    /**
     * Log out
     */
    logout() {
      return new Promise((resolve) => {
        HttpService.post(API.url('logout'), {}, { withCredentials: true })
          .then(() => {
            this.commit('updateToken', null);
            this.commit('setSelf', {});
            resolve();
          });
      });
    },

    /**
     * Pre Login: Obtain access token from refresh token
     */
    prelogin() {
      return new Promise((resolve, reject) => {
        HttpService.post(API.url('refresh'), {}, { withCredentials: true })
          .then((response) => {
            this.commit('updateToken', response.data.access);
            resolve(response);
          })
          .catch((response) => { reject(response); });
      });
    },

    /**
     * Register user
     */
    registerUser(commit, { password, email, invitation_code, recaptcha }) {
      return new Promise((resolve, reject) => {
        HttpService.post(
          API.url('register'),
          { password, email, invitation_code, recaptcha },
          API.getHeaders(),
        )
          .then((response) => {
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },

    /**
     * Activate user
     */
    activateUser(commit, { uidb, token }) {
      return new Promise((resolve, reject) => {
        HttpService.post(API.url('activate'), { uidb, token }, API.getHeaders())
          .then((response) => {
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },

    /**
     * Request password recovery email
     */
    recoverPassword(commit, { email, recaptcha }) {
      return new Promise((resolve, reject) => {
        HttpService.post(API.url('recoverPassword'), { email, recaptcha }, API.getHeaders())
          .then((response) => {
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },

    /**
     * Reset user password
     */
    resetPassword(commit, { password, uidb, token }) {
      return new Promise((resolve, reject) => {
        HttpService.post(API.url('resetPassword'), { password, uidb, token }, API.getHeaders())
          .then((response) => {
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },
  },
  getters: {
    self(state) { return state.self; },
    userIsLogged(state) {
      return (!!state.accessToken || !!API.getToken()) && (!!state.self && !!state.self.id);
    },
  },
};
