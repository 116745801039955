<template>
  <section class="rescontacts">
    <div class="rescontacts__wrap">

      <BlockSubtitle modal="agenda">{{$t('common.agenda')}}</BlockSubtitle>

      <InputText
        name="search_contact"
        v-model.trim="filteredName"
        v-show="results.length"
        :placeholder="$t('common.search_contacts')"></InputText>

      <div class="rescontacts__list">

        <div v-for="(contact, i) in paginatedResults(results)" :key="i" class="rescontacts__item">
          <div class="rescontacts__entity">{{contact.entity}}</div>
          <div class="rescontacts__name">
            <span>{{contact.name}}</span>
            <span v-if="contact.url && contact.platform">
              <a
                class="rescontacts__social"
                :href="`mailto:${contact.email}`"
                :title="$t('common.email')"
                style="background-image: url(/img/icons/social/email.svg)"></a>
              <a
                class="rescontacts__social"
                :title="capitalize(contact.platform)"
                :style="{backgroundImage: getSocialIcon(contact.platform)}"
                rel="noopener"
                :href="contact.url"
                target="_blank"></a></span>
          </div>
        </div>

      </div>

      <div v-show="paginatedResults(results).length == 0">
        <Warn type="info">{{$t('error.no_contacts_to_show')}}</Warn>
      </div>

      <div class="rescontacts__pagination" v-if="getPagesLength(results).length > 1">
        <div class="rescontacts__page rescontacts__page--prev" v-if="showPrevius(results)" @click="current_page -= 1">
          {{$t('common.previous')}}
        </div>
        <div
          v-for="(page, i) in getPagesLength(results)"
          :key="i"
          class="rescontacts__page"
          :class="{'is-current': page === current_page }"
          @click="current_page = page">{{page}}</div>
        <div class="rescontacts__page rescontacts__page--next" v-if="showNext(results)" @click="current_page += 1">
          {{$t('common.next')}}
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'ResultsAgendaComponent',
  data() {
    return {
      current_page: 1,
      page_size: 12,
      filteredName: '',
    };
  },
  props: {
    results: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  methods: {
    capitalize(str) {
      const socialname = str.toLowerCase();
      return `${socialname.charAt(0).toUpperCase()}${socialname.slice(1)}`;
    },
    filterResults(results) {
      if (!this.filteredName) return results;
      const name = this.filteredName.toLowerCase();
      return results
        .filter((c) => (
          c.name.toLowerCase().indexOf(name) !== -1
          || c.entity.toLowerCase().indexOf(name) !== -1
        ));
    },
    paginatedResults(results) {
      return this.filterResults(results)
        .slice((this.current_page - 1) * this.page_size, this.current_page * this.page_size);
    },
    getPagesLength(results) {
      const totalPages = Math.ceil(this.filterResults(results).length / this.page_size);
      const pages = Array(totalPages).fill().map((_, idx) => idx + 1);
      const currentIdx = pages.findIndex((n) => n === this.current_page);
      if (currentIdx < 2) {
        return pages.slice(0, 3);
      }
      if (currentIdx > pages.length - 3) {
        return pages.slice(pages.length - 3);
      }
      return pages.slice(currentIdx - 1, currentIdx + 2);
    },
    showPrevius() {
      return this.current_page > 1;
    },
    showNext(results) {
      const totalPages = Math.ceil(this.filterResults(results).length / this.page_size);
      return this.current_page < totalPages;
    },
    getSocialIcon(platform) {
      return `url(/img/icons/social/${platform.toLowerCase()}.svg)`;
    },
  },
  watch: {
    filteredName() {
      this.current_page = 1;
    },
  },
};
</script>

<style lang="scss">
.rescontacts {
  &__list {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    flex: 1 1 100%;
    background: var(--background-color-bluish);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border-bottom: 3px solid var(--button-box-shadow-color-default);
  }
  &__entity {
    font-weight: 700;
  }
  &__social {
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    a {
      margin-left: 5px;
    }
  }
  &__pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  &__page {
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-left: 6px;
    background: var(--background-color-darker);
    text-align: center;
    font-size: 12px;
    cursor: pointer;

    &--next {
      width: auto;
      padding: 0 5px;
    }
    &--prev {
      width: auto;
      padding: 0 5px;
    }

    &.is-current {
      background: var(--background-color-inverted);
      color: var(--text-color-inverted);
    }
  }
}

@media screen and (min-width: 780px) {
  .rescontacts {
    &__list {
      width: calc(100% + 10px);
    }
    &__item {
      flex: 0 0 calc(33.33% - 10px);
      box-sizing: border-box;
      margin-right: 10px;
      padding: 16px;
    }
  }
}

</style>
