<template>
  <InputSelect
    :name="name"
    :label="label"
    :help="help"
    :isDisabled="isDisabled"
    :isRequired="isRequired"
    :focusOnMount="focusOnMount"
    :isDynamic="true"
    optionComponent="CompanyChip"
    :optionComponentAttrs="optionComponentAttr"
    v-model="value"
    @input="onInputChange"
    :options="results"
    :placeholder="$t('common.search_entities')"
    :classes="['input-block--entity', ...classes]">
      <slot></slot>
  </InputSelect>
</template>

<script>
/* eslint-disable no-lonely-if */
import InputProps from '../InputProps';
import InputMixin from '../InputMixin';

export default {
  name: 'InputEntity',
  mixins: [
    InputMixin,
  ],
  data() {
    return {
      results: [],
    };
  },
  props: {
    ...InputProps.props,
    modelValue: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    optionComponentAttr: {
      type: Function,
      required: false,
      default: (company) => ({ company }),
    },
    options: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    filters: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    isValid() {
      return true;
    },
  },
  mounted() {
    if (this.modelValue?.id) this.results.push(this.modelValue);
    if (this.options) this.results = [...this.options];
  },
  methods: {
    onInputChange(val) {
      if (val) {
        // Filter/search values
        if (this.options.length) {
          // Options are provided, so filter them
          this.filterEntity(val.toLowerCase());
        } else {
          // options aren't provided, so query the database
          this.searchEntity(val.toLowerCase());
        }
      } else {
        // Restore values
        if (this.options) {
          // Options are provided, so restore them
          this.results = [...this.options];
        } else {
          // Options are not provided, so empty list
          this.results = [];
        }
      }
    },
    searchEntity(search) {
      this.$store.dispatch('list', {
        model: 'entity',
        params: {
          ...this.filters,
          search,
          query: 'created',
        },
      }).then((response) => {
        this.results = response.data.results;
      });
    },
    filterEntity(val) {
      this.results = this.options.filter((entity) => (
        entity.name.toLowerCase().includes(val) || entity.cif.toLowerCase().includes(val)
      ));
    },
  },
};
</script>

<style lang="scss">
.input-block--entity {
  .inputselect__head {
    height: 74px;
  }
  &.input-block--slim .inputselect__head {
    height: initial;
  }
}
</style>
