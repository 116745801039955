<template>
  <InputSelect
    :name="name"
    :classes="classes"
    :label="label"
    :help="help"
    :isDisabled="isDisabled"
    :isRequired="isRequired"
    :focusOnMount="focusOnMount"
    v-model="value"
    :options="years">
    <template #link>
      <slot name="link"></slot>
    </template>
  </InputSelect>
</template>

<script>
import GeneratorService from '@/services/generator/GeneratorService';
import InputMixin from '../InputMixin';

export default {
  name: 'InputYear',
  mixins: [
    InputMixin,
  ],
  computed: {
    years() {
      return GeneratorService.years(2020, 2030);
    },
  },
};
</script>
