<!-- eslint-disable max-len -->
<template>
  <div class="rescount">
    <div v-if="shareOption" class="share">
      <img src="/img/icons/social/share.svg" width="20" height="20" alt="">
    </div>
    <img class="rescount__icon" src="/img/icons/search.png" alt="Search Icon" />
    <div v-if="results === 0" class="rescount__noresuls">
      {{$t('results.no_results')}}
    </div>
    <div v-else-if="rankingCategory">
      <strong>{{$t('results.$category', { category: rankingCategory })}}</strong>
    </div>
    <div v-else-if="hasSearchParam()">
      <strong>{{$t('results.$number_found', { number: results })}}</strong>
      <span v-if="getSearchParam('type')">{{$t('results.entities_$type', { type: translateType('type') })}}</span>
      <span v-if="getSearchParam('field')">{{$t('results.who_accomplished_the_$challenge', { challenge: translateField('field') })}}</span>
      <span v-if="getSearchParam('sdg')">{{$t('results.who_has_prioritary_$sdg', { sdg: translateODS('sdg') })}}</span>
      <span v-if="getSearchParam('sector')">{{$t('results.who_belongs_to_$sector', { sector: translateSector('sector') })}}</span>
      <span v-if="getSearchParam('adm1')">{{$t('results.who_headquarters_are_in_$community', { community: translateRegion('adm1') })}}</span>
      <span v-if="getSearchParam('category')">{{$t('results.whose_category_is_$category', { category: getSearchParam('category') })}}</span>
      <span v-if="getSearchParam('search')">{{$t('results.whose_name_has_$name', { name: getSearchParam('search') })}}</span>
    </div>
    <div v-else>
      <strong>{{$t('results.all_results')}}.</strong>
      <span>{{$t('results.all_results_$number', { number: results })}}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UrlService from '@/services/url/UrlService';

const urlService = new UrlService();

export default {
  name: 'ResultsCount',
  props: {
    results: {
      type: Number,
      required: true,
      default: 0,
    },
    rankingCategory: {
      type: String,
      required: false,
    },
    shareOption: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['entityTypes', 'entitySectors', 'goals', 'adm1']),
  },
  methods: {
    hasSearchParam() {
      return !!this.getSearchParam('type')
        || !!this.getSearchParam('field')
        || !!this.getSearchParam('sdg')
        || !!this.getSearchParam('sector')
        || !!this.getSearchParam('adm1')
        || !!this.getSearchParam('search')
        || !!this.getSearchParam('challenge')
        || !!this.getSearchParam('category');
    },
    getSearchParam(param) {
      return urlService.getQueryStringParam(window.location.href, param);
    },
    translateType(param) {
      return this.$t(`entityType.${this.getSearchParam(param)}`);
    },
    translateField(param) {
      const value = this.reportFields.find((d) => d.key === this.getSearchParam(param));
      return this.$t(`reportField.${value.key}_name`);
    },
    translateSector(param) {
      return this.$t(`entitySector.${this.getSearchParam(param)}`);
    },
    translateODS(param) {
      const params = Array.isArray(this.getSearchParam(param))
        ? this.getSearchParam(param)
        : [this.getSearchParam(param)];
      return params.map((code) => this.$t(`sdg.${code}`)).join(', ');
    },
    translateRegion(param) {
      const value = this.adm1.find((d) => d.id === +this.getSearchParam(param));
      return this.$t(`adm1.${value.search_name}`);
    },
  },
};
</script>

<style lang="scss">
.share {
    text-align: right;
    margin: 0 auto;
    max-width: var(--layout-max-width-default);
  }
.rescount {
  color: var(--text-color-blue);
  background: var(--background-color-bluish);
  text-align: center;
  margin-bottom: 40px;
  border-bottom: 3px solid var(--button-box-shadow-color-default);
  border-radius: 4px;
  &__icon {
    width: 40px;
    height: auto;
    margin-bottom: 20px;
  }
  &__noresuls {
    margin: 40px 0;
  }
  &__yeahresuls {
  }
  span + span:before {
    content: ', ';
  }
  span:nth-child(2):before {
    content: ' ';
  }
  span:last-child:after {
    content: '.';
  }
}
</style>
