import InputProps from './InputProps';

export default {
  emits: ['input', 'enter', 'update:modelValue'],
  mounted() {
    if (this.focusOnMount && this.$refs.input) {
      this.$refs.input.focus();
    }
  },
  props: InputProps.props,
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    isValid() {
      return (this.isRequired || this.runValidations) && this.value
        ? this.validators.every((func) => func(this.value))
        : true;
    },
  },
  methods: {
    focus() {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
  },
};
