<template>
  <div class="block-subtitle">
    <h3 class="block-subtitle__subtitle">
      <slot></slot>
    </h3>
    <div v-if="modal" @click="isModalOpen = true" class="block-subtitle__toggle">?</div>

    <teleport to="body">
      <Modal v-if="isModalOpen" @overlayClick="isModalOpen = false">
        <template #header>{{$t(`modal.${modal}.title`)}}</template>
        <div>{{$t(`modal.${modal}.body`)}}</div>
        <template #footer>
          <Button @onclick="isModalOpen = false">{{$t('common.close')}}</Button>
        </template>
      </Modal>
    </teleport>

  </div>
</template>

<script>
export default {
  name: 'BlockSubtitle',
  data() {
    return {
      isModalOpen: false,
    };
  },
  props: {
    modal: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.block-subtitle {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: baseline;

  &__subtitle {
    margin-bottom: 0;
    padding: 10px 20px;
    border-radius: 30px;
    color: white;
    background: var(--primary-color-default);
    font-size: 12px;
    font-weight: 600;
    text-transform: none !important;
  }

  &__toggle {
    display: block;
    align-self: center;
    color: var(--primary-color-default);
    background: var(--background-color-darker);
    line-height: 24px;
    margin-left: 10px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    cursor: pointer;
    font-weight: 700;
  }
}

@media screen and (min-width: 780px) {
  .block-subtitle {
    margin-top: 80px;
  }
}
@media screen and (min-width: 1200px) {
  .block-subtitle {
    margin-top: 100px;
  }
}
</style>
