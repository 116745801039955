<template>
  <Chip
    :thumbnail="getThumbnail"
    :letter="getLetter"
    :color="company.color"
    :classes="[...classes, 'chip--company']">

    <div class="chip__info chip__info--primary">
      {{company.acronim}}
    </div>
    <div class="chip__info chip__info--tertiary" v-if="company.address">
      {{company.name}}
    </div>
    <div class="chip__info chip__info--secondary">
      {{company.number}}
    </div>

  </Chip>
</template>

<script>
import Chip from '@/components/chips/Chip.vue';
import ChipMixin from '@/components/chips/ChipMixin';
import ImageService from '@/services/image/ImageService';

export default {
  name: 'CompanyChip',
  mixins: [
    ChipMixin,
  ],
  components: {
    Chip,
  },
  props: {
    company: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getLetter() {
      return this.company?.name
        ? this.company.name.charAt(0).toUpperCase()
        : '';
    },
    getThumbnail() {
      if (!this.company?.logo) return null;
      return ImageService.imageUrl(this.company.logo);
    },
  },
};
</script>
