<template>
  <div class="page" id="page" :class="classes">

    <div class="page__header" v-if="title">
      <BlockTitle
        :buttons="buttons"
        @onAdd="$emit(`on-add`, $event)"
        @onEdit="$emit(`on-edit`, $event)"
        @onCancel="$emit(`on-cancel`, $event)"
        @onExit="$emit(`on-exit`, $event)">
        {{title}}
      </BlockTitle>
    </div>

    <div class="page__main">
      <div class="page__side" v-if="tabs && tabs.length">
        <Tabs
          :tabs="tabs"
          :currentTab="currentTab"
          @onclick="tab = $event"
        ></Tabs>
      </div>
      <div class="page__body">
        <slot></slot>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Page',
  emits: ['on-add', 'on-edit', 'on-cancel', 'on-exit'],
  props: {
    title: {
      type: String,
      default: '',
    },
    currentTab: {
      type: Object,
      default: () => ({}),
    },
    buttons: {
      type: Array,
      default: () => ([]),
    },
    tabs: {
      type: Array,
      default: () => ([]),
    },
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    tab: {
      get() {
        return this.currentTab;
      },
      set(value) {
        console.log(value);
        this.$emit('update:currentTab', value);
      },
    },
  },
};
</script>

<style lang="scss">
.page {
  position: relative;
  padding: 20px 10px;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 100%;
  &--vcenter {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  &.page--narrow  {
    max-width: var(--layout-max-width-narrow);
  }

  .block-title {
    margin-bottom: 14px;
  }

  &--static {
    ul {
      padding-left: 30px;
      margin-bottom: 24px;
    }
    li {
      list-style: disc;
    }
  }
}

@media screen and (min-width: 780px) {
  .page {
    padding: 30px 20px 40px;
  }
}

@media screen and (min-width: 1200px) {
  .page {
    padding: 40px 40px 80px;
    max-width: var(--layout-max-width-default);
    margin: 0 auto;
    .block-title {
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }
}
</style>
