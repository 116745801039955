import { createRouter, createWebHistory } from 'vue-router';
import i18n from '@/i18n';
import routes from './routes';
import guards from './guards';

const loadLocales = () => {
  const locales = require.context('@/i18n/locales', true, /[\w-]+\.json$/i);
  return locales.keys().reduce((locs, loc) => ({ ...locs, [loc.replace(/\.|\/|json/g, '')]: locales(loc) }), {});
};

const setLocale = (to, from, next) => {
  const code = to.params.locale || 'es';
  const messages = loadLocales()[code];
  i18n.global.locale = code;
  i18n.global.setLocaleMessage(code, messages || {});
  next();
};

// '([A-z]{2})'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // routes: routes.map((route) => ({ ...route, beforeEnter: setLocale })),
  routes: routes.map((route) => ({
    ...route,
    path: `/:locale([A-z]{2})?${route.path}`,
    beforeEnter: setLocale,
  })),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 100,
      };
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach(guards);

export default router;
