<template>
  <div ref="map" :style="{height: `${height}px`}"></div>
</template>

<script>
import D3MapPoints from '@/services/map/MapPointsService';

export default {
  name: 'MapPoints',
  emits: ['move', 'click', 'dblclick'],
  props: {
    height: {
      type: Number,
      required: false,
      default: 400,
    },
    lat: {
      type: Number,
      required: false,
      default: 0,
    },
    lng: {
      type: Number,
      required: false,
      default: 0,
    },
    zoom: {
      type: Number,
      required: false,
      default: 6,
    },
    points: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  mounted() {
    this.map = new D3MapPoints(this.$refs.map, [], {
      lat: this.lat,
      lng: this.lng,
      zoom: this.zoom,
    });
    this.map.on('move', (position) => {
      this.$emit('move', position);
    });
    this.map.on('click', (location) => {
      this.$emit('click', location);
    });
    this.map.on('dblclick', (location) => {
      this.$emit('dblclick', location);
    });
    this.updatePoints(this.points);
  },
  methods: {
    updatePoints(points) {
      this.map.removeAllPoints();
      this.map.loadPoints(points);
    },
  },
  watch: {
    points: {
      handler(points) {
        this.updatePoints(points);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
@import './../../../services/map/styles.css';

.map-point {
  stroke-width: 1px;
}
</style>
