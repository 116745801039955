<template>
  <div class="footer">
    <div class="footer__wrap">
      <div class="footer__block footer__block--left">
        <img
          :src="`/img/${$i18n.locale}/footer/goals.svg`"
          @error="$event.target.src = `/img/es/footer/goals.svg`"
          alt="Objetivos"
        >
        <img src="/brand/logo.svg" alt="OpenData">
      </div>
      <div class="footer__block footer__block--center">
        <ul class="footer__menu">
          <li>
            <router-link :to="{name: 'help', params: { locale: $i18n.locale }}">{{$t('common.help')}}</router-link>
          </li>
          <li>
            <router-link :to="{name: 'info', params: { locale: $i18n.locale }}">{{$t('common.information')}}</router-link>
          </li>
          <li>
            <router-link :to="{name: 'sistema', params: { locale: $i18n.locale }}">{{$t('common.system')}}</router-link>
          </li>
          <li>
            <a href="https://openods.es/" target="_blank">OpenODS</a>
          </li>
          <li>
            <router-link :to="{name: 'contact', params: { locale: $i18n.locale }}">{{$t('common.contact')}}</router-link>
          </li>
        </ul>
      </div>
      <div class="footer__block footer__block--right">
        <p>{{$t('common.project_by')}}</p>
        <div><img :src="`/img/openods-white.svg`" alt="OpenODS"></div>
      </div>
    </div>
    <hr>
    <div class="footer__bottom">
      <ul>
        <li>2022 OpenODS S.L</li>
        <li class="divider"><img src="/img/dots3.svg" alt=""></li>
        <li><router-link :to="{name: 'tos', params: { locale: $i18n.locale }}">{{$t('tos.title')}}</router-link></li>
        <li class="divider"><img src="/img/dots3.svg" alt=""></li>
        <li><router-link :to="{name: 'cookies', params: { locale: $i18n.locale }}">{{$t('cookies.title')}}</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss">
.footer {
  padding: 20px 10px;
  border-top: 1px solid var(--border-color-default);
  background-color: var(--background-color-dark-blue);

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    max-width: var(--layout-max-width-default);
    margin: 0 auto;
  }

  &__block {
    flex: 1 1 100%;
    img {
      display: block;
      max-width: 100%;
    }
    &--left {
      margin-bottom: 40px;
      img {
        width: 100%;
        max-width: 300px;
        margin: 0 auto 20px auto;
      }
    }
    &--center {
      margin-bottom: 40px;
    }
    &--right {
      display: flex;
      flex-wrap: wrap;
      p {
        flex: 1 1 100%;
        text-align: center;
        margin-bottom: 4px;
        color: var(--text-color-inverted);
      }
      > div {
        flex: 1 1 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      img {
        margin: 0 auto;
        height: auto;
      }
    }
  }
  &__menu {
    text-align: center;
    li {
      display: block;
      a {
        color: var(--text-color-inverted) !important;
      }
    }
  }
  hr {
    background-color: var(--text-color-inverted);
    margin-left: -10px;
    margin-right: -10px;
  }
  &__bottom {
    margin-top: 40px;
    text-align: center;
    li {
      font-family: var(--font-family-details);
      font-size: 12px;
      margin-bottom: 6px;
      color: var(--text-color-inverted);
      text-align: center;
    }
    a {
      color: var(--text-color-inverted) !important;
    }
    .divider {
      display: none;
      img {
        height: 15px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 60px 40px 30px;
    hr {
      margin-left: -40px;
      margin-right: -40px;
    }
    &__wrap {
      flex-wrap: nowrap;
    }
    &__block {
      margin-bottom: 0;
      flex: 1 1 33.33%;
      &--center {
        flex: 1 1 20%;
      }
      &--right {
        display: block;
        p {
          text-align: left;
          margin-bottom: 10px;
        }
        > div {
          display: inline-block;
          margin-right: 10px;
          vertical-align: top;
        }
        img {
          margin-left: 0;
          height: 50px;
        }
      }
    }
    &__menu {
      text-align: left;
      padding: 0 40px;
    }
    &__bottom {
      ul {
        display: flex;
        justify-content: center;
      }
      li {
        margin: 0 10px;
      }
      .divider {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .footer {
    &__block {
      &--center {
        flex: 1 1 33.33%;
      }
      &--right {
        img {
          height: 70px;

        }
      }
    }
  }
}
</style>
