module.exports = [
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/components/staticpages/ContactPage.vue"),
    meta: {
      isPublic: true
    }
  },
  {
    path: "/termsofservice",
    name: "tos",
    component: () => import("@/components/staticpages/TosPage.vue"),
    meta: {
      isPublic: true
    }
  },
  {
    path: "/termsofservice",
    name: "tos",
    component: () => import("@/components/staticpages/TosPage.vue"),
    meta: {
      isPublic: true
    }
  },
  {
    path: "/cookies",
    name: "cookies",
    component: () => import("@/components/staticpages/CookiesPage.vue"),
    meta: {
      isPublic: true
    }
  },
  {
    path: "/info",
    name: "info",
    component: () => import("@/components/staticpages/InfoPage.vue"),
    meta: {
      isPublic: true
    }
  },
  {
    path: "/sistema",
    name: "sistema",
    component: () => import("@/components/staticpages/SystemPage.vue"),
    meta: {
      isPublic: true
    }
  },
  {
    path: "/aquienvadirigido",
    name: "aquienvadirigido",
    component: () => import("@/components/staticpages/TargetPage.vue"),
    meta: {
      isPublic: true
    }
  }
];
