module.exports = [
  {
    path: '/entities/new',
    name: 'new-entity',
    component: () => import('@/components/entities/EntityEditPage.vue'),
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/entities/:entityid(\\d+)/edit',
    name: 'edit-entity',
    component: () => import('@/components/entities/EntityEditPage.vue'),
    meta: {
      isPublic: false,
    },
  },
  {
    path: '/entities/:entityid(\\d+)',
    name: 'entity',
    component: () => import('@/components/entities/EntityPage.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/entities/',
    name: 'entities',
    component: () => import('@/components/entities/EntitiesPage.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/entities/verify',
    name: 'entities-verification',
    component: () => import('@/components/entities/EntitiesVerificationPage.vue'),
    meta: {
      isPublic: false,
    },
  },
];
