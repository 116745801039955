<template>
  <caption class="pagination" v-show="pagination.count">
    <div class="pagination__wrap">

      <div class="pagination__data">
        <InputSelect
          name="status"
          :classes="['input-block--narrow']"
          :label="$t('common.limit')"
          v-model="limit"
          :isSearchable="false"
          :options="limitOptions"
        ></InputSelect>
        <div class="pagination__results">
          <strong>{{pagination.count}}</strong> resultados
        </div>
      </div>

      <div class="pagination__pages" v-if="hasPreviousPage || hasNextPage">
        <Button
          :isDisabled="!hasPreviousPage"
          class="btn--small"
          @onclick="paginateUrl(pagination.previous)">
          {{$t('common.previous')}}
        </Button>
        <Button
          :isDisabled="!hasNextPage"
          class="btn--small"
          @onclick="paginateUrl(pagination.next)">
          {{$t('common.next')}}
        </Button>
      </div>

    </div>
  </caption>
</template>

<script>
import UrlService from '@/services/url/UrlService';

const URL = new UrlService();

export default {
  name: 'TablePagination',
  emits: ['update:offset', 'update:limit', 'set-offset'],
  data() {
    return {
      limitOptions: [{
        v: 10,
        n: 10,
      }, {
        v: 20,
        n: 20,
      }, {
        v: 50,
        n: 50,
      }, {
        v: 100,
        n: 100,
      }],
    };
  },
  props: {
    offset: {
      type: Number,
      required: true,
      default: 0,
    },
    pagination: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    currentPage() {
      if (this.pagination.previous === null) return 1;
      if (!this.offset) return 1;
      return Math.floor(this.offset / this.pagination.limit);
    },
    totalPages() {
      if (!this.pagination.count) return 0;
      return Math.floor(this.pagination.count / this.pagination.limit);
    },
    hasNextPage() {
      return this.pagination.next !== null;
    },
    hasPreviousPage() {
      return this.pagination.previous !== null;
    },
    limit: {
      get() {
        if (this.pagination.limit) return +this.pagination.limit;
        if (this.limit) return +this.limit;
        return +process.env.VUE_APP_API_LIMIT_DEFAULT;
      },
      set(value) {
        this.$emit('update:limit', +value);
      },
    },
  },
  methods: {
    paginateUrl(url) {
      const offset = URL.getQueryStringParam(url, 'offset') || 0;
      this.$emit('set-offset', +offset);
    },
  },
};
</script>

<style lang="scss">
.pagination {
  caption-side: bottom;

  &__wrap {
    display: flex;
    justify-content: space-between;
  }
  &__data {
    display: flex;
    padding: 8px 8px 8px 0;
    line-height: 32px;
    strong {
      margin-right: 6px;
    }
    .input-block--select {
      width: 100px;
      margin-top: 3px;
      margin-right: 9px;
      max-height: 25px;
      padding: 0;
      .inputselect.is-single .inputselect__selected > div {
        padding: 3px 10px;
      }
    }
  }
  &__pages {
    display: flex;
    padding: 8px;
    > div {
      padding: 0 2px;
    }

    .btn {
      margin-left: 4px;
    }
  }
}
</style>
