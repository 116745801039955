<template>
  <div class="overlay" @click="$emit('close')"></div>
</template>

<script>
export default {
  name: 'Overlay',
  emits: ['close'],
  data() {
    return {
      isActive: true,
    };
  },
  props: {
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
}
</style>
