<template>
  <div
    class="input-block"
    :class="[ ...classes, {
      'is-required': isRequired,
      'is-invalid': isInvalid,
      'is-warning': isWarning,
      'is-disabled': isDisabled,
    }]">

    <div class="input-block__headers">
      <div class="input-block__header input-block__header--primary">
        <span
          v-if="isRequired"
          class="input-block__required">*&nbsp;</span>
        <label
          v-if="label"
          :for="name"
          class="input-block__label">{{label}}</label>
        <span
          v-if="help"
          :title="help"
          class="input-block__help">?</span>
        <slot name="header"></slot>
      </div>

      <div class="input-block__header input-block__header--secondary">
        <span class="input-block__link">
          <slot name="link"></slot>
        </span>
      </div>
    </div>

    <div class="input-block__body">
      <slot></slot>
    </div>

    <div class="input-block__alerts">
      <slot name="alerts"></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'InputBlock',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    help: {
      type: String,
      required: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>
