<template>
  <div
    class="statuschip"
    :class="`statuschip--${value}`"
    :title="$t(`status.${value}`)">
    {{$t(`status.${value}`)}}
  </div>
</template>

<script>
export default {
  name: 'Status',
  props: {
    value: {
      type: String,
      required: true,
      default: 'default',
    },
  },
};
</script>

<style lang="scss">
.statuschip {
  font-weight: 500;
  display: inline-block;
  padding: 4px 7px;
  border-radius: 4px;
  line-height: 1;

  &--active,
  &--paid,
  &--true,
  &--accepted,
  &--approved,
  &--finished {
    background-color: var(--status-success-background-color);
    color: var(--status-success-color);
  }
  &--info,
  &--canceled,
  &--null,
  &--undefined,
  &--default,
  &--void {
    background-color: var(--status-info-background-color);
    color: var(--status-info-color);
  }
  &--processing,
  &--pending {
    background-color: var(--status-warn-background-color);
    color: var(--status-warn-color);
  }
  &--false,
  &--declined,
  &--incomplete_expired {
    background-color: var(--status-alert-background-color);
    color: var(--status-alert-color);
  }
}
</style>
