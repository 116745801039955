<template>
  <div class="tablerowactions">
    <div
      v-for="(action, i) in actions"
      :key="i"
      :class="[
        `tablerowactions__action--${getClass(action)}`,
        action.button ? 'tablerowactions__button' : 'tablerowactions__action',
        action.isActive !== false ? 'is-active' : '',
      ]"
      :style="{backgroundImage: `url(/img/icons/table/${getClass(action)}.svg)`}"
      :title="$t(`common.${getClass(action)}`)"
      @click="actionExecution(action)">
      <button
        class="btn btn--small"
        v-if="action.button">
        {{action.button}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableRowActions',
  props: {
    actions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    row: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  methods: {
    actionExecution(action) {
      if (action.isActive !== false) {
        action.function(this.row);
      }
    },
    getClass(action) {
      return action.class(this.row);
    },
  },
};
</script>

<style lang="scss">
.tablerowactions {
  &__action {
    display: inline-block;
    vertical-align: top;
    border-radius: 100%;
    line-height: 0;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-color 250ms ease;
    &.is-active {
      cursor: pointer;
      &:hover {
        background-color: var(--background-color-darker);
      }
    }
    &--no_file {
      visibility: hidden;
      pointer-events: none;
    }
  }
}
</style>
