import store from '@/store';

/**
 * Auth guard
 * Check if the user is logged before enter a private route
 */
const authGuard = (to) => new Promise((resolve, reject) => {
  if (!to.matched.some((record) => record.meta.isPublic)) {
    if (!store.getters.userIsLogged) {
      store.dispatch('prelogin')
        .then(() => {
          resolve(to);
        })
        .catch(() => {
          reject({ name: 'login' });
        });
    } else {
      resolve(to);
    }
  } else {
    resolve(to);
  }
});

/**
 * Entity data guard
 * Request the entity data if the route depends on it
 */
const entityGuard = (to) => new Promise((resolve, reject) => {
  if (to.params?.entityid && store.getters.entity?.id !== +to.params.entityid) {
    // If route depends on entityid, get it
    store.dispatch('retrieve', {
      model: 'entity',
      id: to.params.entityid,
      updateModel: true,
    }).then(() => {
      resolve(to);
    }).catch(() => {
      reject({ name: 'home' });
    });
  } else if (to.params?.entityslug && store.getters.entity?.id !== +to.params.entityslug) {
    // If route depends on entityslug, get it
    store.dispatch('retrieveBySlug', {
      model: 'entity',
      slug: to.params.entityslug,
      updateModel: true,
    }).then(() => {
      resolve(to);
    }).catch(() => {
      reject({ name: 'home' });
    });
  } else {
    resolve(to);
  }
});

/**
 * Guards chain execution
 * Execute guards. Add here your new guards
 */
const guards = (to, from, next) => {
  authGuard(to)
    .then(entityGuard)
    .then(() => { next(); })
    .catch((route) => { next(route); });
};

export default guards;
