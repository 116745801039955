<template>
  <div class="warn" :class="[...classes]">
    <div class="warn__wrap" :class="`warn__wrap--${type}`">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Warn',
  props: {
    type: {
      type: String,
      default: 'info',
    },
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss">
.warn {
  &__wrap {
    padding: 10px 18px;
    border-radius: 4px;
    font-size: 14px;
    line-height: normal;
    box-sizing: border-box;
    &--info {
      background-color: var(--status-info-background-color);
      color: var(--status-info-color);
    }
    &--warn {
      background-color: var(--status-warn-background-color);
      color: var(--status-warn-color);
    }
    &--error {
      background-color: var(--status-alert-background-color);
      color: var(--status-alert-color);
    }
  }
  &--inline {
    .warn__wrap {
      display: inline-block;
    }
  }
}
</style>
