export default {
  emits: [
    'thumb-click',
    'body-click',
  ],
  props: {
    classes: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
};
