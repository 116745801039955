<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired"
    :isDisabled="isDisabled"
    :classes="[
      'input-block--image',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <div class="inputimage">

      <div class="inputimage__preview">
        <!-- saved image -->
        <div
          v-show="isDisabled"
          class="inputimage__preview inputimage__preview--file"
          :style="viewportStyles">
          <img :src="imageProxy">
        </div>
        <!-- end saved image -->
        <!-- cropper -->
        <div
          v-show="!isDisabled"
          class="inputimage__preview inputimage__preview--cropper"
          :style="viewportStyles">
          <img alt="viewport" ref="output" :src="imageProxy">
        </div>
        <!-- end cropper -->
      </div>

      <div class="inputimage__controls">
        <div class="inputimage__slot">
          <slot></slot>
        </div>
        <div v-if="!isDisabled" class="inputimage__buttons">
          <Button
            class="btn--small"
            @onclick="this.$refs.input.click()">
            {{$t('common.change')}}
          </Button>
          <Button
            v-if="isDirty"
            class="btn--small"
            @click="cancelChanges">{{$t('common.cancel')}}</Button>
          <Button
            v-if="isDirty"
            class="btn--small btn--accent"
            @click="saveImage">{{$t('common.save')}}</Button>
        </div>
      </div>

    </div>

    <input
      :id="name"
      :name="name"
      ref="input"
      accept="image/*"
      type="file"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :required="isRequired"
      @input="uploadFile($event)">

  </InputBlock>
</template>

<script>
import ImageService from '@/services/image/ImageService';
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputImage',
  mixins: [
    InputMixin,
  ],
  components: {
    InputBlock,
  },
  data() {
    return {
      isDirty: false,
      imageService: null,
      imageProxy: null,
    };
  },
  props: {
    options: {
      type: Object,
      required: false,
      default: () => ({
        viewport: { width: 200, height: 200 },
      }),
    },
  },
  mounted() {
    this.imageService = new ImageService(
      this.$refs.output,
      { ...this.options },
    );

    if (this.value) {
      this.imageProxy = ImageService.imageUrl(this.value);
      this.imageService.loadImage(this.imageProxy);
    } else {
      this.imageProxy = '/img/icons/image/upload.png';
      this.imageService.loadImage(this.imageProxy);
    }
  },
  computed: {
    viewportStyles() {
      return {
        width: `${this.options.viewport.width || 200}px`,
        height: `${this.options.viewport.height || 200}px`,
        borderRadius: `${this.options.radius || 0}px`,
      };
    },
  },
  methods: {
    uploadFile(event) {
      const file = event.target.files[0];
      this.imageService.loadImage(file).then(() => {
        this.isDirty = true;
      });
    },
    saveImage() {
      this.imageService.getBase64().then((str64) => {
        this.value = str64;
        this.isDirty = false;
      });
    },
    cancelChanges() {
      if (this.value) {
        this.imageProxy = ImageService.imageUrl(this.value);
        this.imageService.loadImage(this.imageProxy);
      }
      this.isDirty = false;
    },
  },
  beforeUnmount() {
    this.imageService.destroy();
  },
};
</script>

<style lang="scss">
.input-block--image {
  input[type="file"] {
    display: none !important;
  }
}

.inputimage {
  display: flex;

  &__preview {
    flex: 0 0 auto;
    overflow: hidden;
    &--nofile {
      background-color: var(--background-color-dark);
      border: 1px solid var(--input-border-color-dark);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__icon {
      width: 26px;
      height: 26px;
      &--upload {
        cursor: pointer;
        margin-bottom: 4px;
      }
    }
  }

  &__controls {
    flex: 1 1 auto;
    margin-left: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__buttons {
    margin-top: 12px;
    .btn {
      margin-right: 4px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .inputimage {
    &__controls {
      margin-left: 24px;
    }

    &__buttons {
      margin-top: 12px;
    }
  }
}
</style>
