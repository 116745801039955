/**
 * KeyComboService class
 * Handles keypress combinations
 *
 * Usage:
 *
 * On your app, register onkeydown and onkeyup events:
 *
 *    document.onkeydown = (e) => (KeyCombo.onkeydown(e));
 *    document.onkeyup = (e) => (KeyCombo.onkeyup(e));
 *
 *
 * Then, in your components, register and unregister listeners:
 *
 *    import KeyCombo from '@/services/keycombo/KeyComboService';
 *    ...
 *
 *    methods: {
 *      onComboCall() {
 *        // do whatever you want
 *      },
 *    },
 *    mounted() {
 *      KeyCombo.newCombo('YourComponentName.Ctrl+Alt+P', [18, 17, 80], this.onComboCall);
 *    },
 *    beforeUnmount() {
 *      KeyCombo.removeCombo('YourComponentName.Ctrl+Alt+P');
 *    },
 *
 */
class KeyComboService {
  constructor() {
    this.pressed = []; // Keys currently pressed
    this.combos = [];
    this.callback = () => ({});
  }

  newCombo(name, keyCodes, fn) {
    if (!this.combos.find((c) => c.name === name) && keyCodes?.length) {
      this.combos.push({ keyCodes, name, fn });
    }
  }

  removeCombo(name) {
    this.combos = this.combos.filter((c) => c.name !== name);
  }

  onkeydown(e) {
    const event = window.event ? window.event : e;
    this.pressed.push(event.keyCode);
    this.evalCommands(event);
  }

  onkeyup(e) {
    const event = window.event ? window.event : e;
    this.pressed = this.pressed.filter((keyCode) => keyCode !== event.keyCode);
  }

  evalCommands(event) {
    for (let i = 0; i < this.combos.length; i += 1) {
      if (this.pressed.length === this.combos[i].keyCodes.length
        && KeyComboService.contains(this.pressed, this.combos[i].keyCodes)) {
        // Execute combo function
        this.combos[i].fn({ name: this.combos[i].name, keyCodes: this.combos[i].keyCodes, event });
      }
    }
  }

  static contains(list, keys) {
    return keys.every((keyCode) => list.includes(keyCode));
  }
}

const KeyCombo = new KeyComboService();

export default KeyCombo;
