<template>
  <h3 class="form-title">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: 'FormTitle',
};
</script>

<style>
.form-title {
  margin-top: 60px;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--border-color-default);
  color: var(--text-color-lighter);
  font-family: var(--font-family-display);
  font-weight: 600;
  font-size: 12px;
  padding-bottom: 4px;
}
</style>
