<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired"
    :isDisabled="isDisabled"
    :classes="[
      'input-block--file',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <div class="inputfile">
      <Button @onclick="this.$refs.input.click()">
        {{$t('common.upload_file')}}
      </Button>
    </div>

    <input
      :id="name"
      :name="name"
      ref="input"
      type="file"
      :disabled="isDisabled"
      :required="isRequired"
      @input="selectFiles($event)">

  </InputBlock>
</template>

<script>
import FileService from '@/services/file/FileService';
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputFile',
  mixins: [
    InputMixin,
  ],
  components: {
    InputBlock,
  },
  methods: {
    selectFiles(event) {
      FileService.loadFiles(event.target.files)
        .then((files) => {
          this.$emit('update:modelValue', files[0]);
        });
    },
  },
};
</script>

<style lang="scss">
.input-block--file {
  input[type="file"] {
    display: none !important;
  }
}
</style>
