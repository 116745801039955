import API from '@/services/api/ApiService';
import HttpService from '@/services/http/HttpService';

export default {
  actions: {
    get(commit, { url, id }) {
      return new Promise((resolve, reject) => {
        HttpService.get(API.url(url, id), API.getHeaders())
          .then((response) => {
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },
    post(commit, { url, data, id }) {
      return new Promise((resolve, reject) => {
        HttpService.post(API.url(url, id), data, API.getHeaders())
          .then((response) => {
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },
    put(commit, { url, data, id }) {
      return new Promise((resolve, reject) => {
        HttpService.put(API.url(url, id), data, API.getHeaders())
          .then((response) => {
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },
    patch(commit, { url, data, id }) {
      return new Promise((resolve, reject) => {
        HttpService.patch(API.url(url, id), data, API.getHeaders())
          .then((response) => {
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },
    delete(commit, { url, id }) {
      return new Promise((resolve, reject) => {
        HttpService.delete(API.url(url, id), API.getHeaders())
          .then((response) => {
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },
  },
};
