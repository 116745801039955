<template>
  <div class="bar">
    <div class="bar__back">
      <span class="bar__value bar__value--back">{{value}}%</span>
    </div>
    <div
      class="bar__over"
      :style="{width: `${value}%`, backgroundColor: color, color: textColor}">
      <span class="bar__value bar__value--over">{{value}}%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bar',
  props: {
    color: {
      type: String,
      default: '#28457d',
    },
    textColor: {
      type: String,
      default: '#FFFFFF',
    },
    value: {
      type: Number,
      required: true,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
$bar-border-radius: 4px;
$bar-height: 24px;

.bar {
  position: relative;
  min-height: 22px;
  &__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: $bar-border-radius;
    height: $bar-height;
    background: var(--background-color-darker);
  }
  &__over {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: $bar-border-radius;
    height: $bar-height;
    overflow: hidden;
  }

  &__value {
    display: block;
    font-size: 14px;
    line-height: $bar-height;
    padding: 0 5px;
  }
}
</style>
