import * as L from 'leaflet';
import MapService from './MapService';

export default class extends MapService {
  constructor(selection, data, config = {}) {
    super(selection, data, config);
    this.circles = {};
    this.pointevents = {
      click: () => ({}),
    };
    this.popup = new L.Popup();

    this.map.on('zoomend', () => {
      Object.keys(this.circles).forEach((key) => {
        this.circles[key].setRadius(this.getCircleSize());
      });
    });
  }

  getCircleSize() {
    if (this.map.getZoom() > 12) {
      // Urban scale
      return 360 - (50 * (this.map.getZoom() - 12));
    }
    if (this.map.getZoom() < 6) {
      // Global scale
      return 100000 - (14000 * this.map.getZoom());
    }
    // Country scale
    return 10000 - (800 * this.map.getZoom());
  }

  loadPoints(points) {
    points.forEach((d) => {
      const lnglat = new L.LatLng(+d.coordinates[1], +d.coordinates[0]);
      const circle = new L.circle(lnglat, { //eslint-disable-line
        color: d.color,
        opacity: 1,
        fillOpacity: 0.5,
        radius: this.getCircleSize(),
        className: 'map-point',
      });
      circle.addTo(this.map);
      /*
      circle.on('click', (e) => {
        this.popup.setLatLng(e.latlng);
        this.popup.setContent(d.name);
        this.map.openPopup(this.popup);
        this.pointevents.click(d);
      });
      */
      this.circles[d.name] = circle;
    });
  }

  removePoints(points) {
    points.forEach((d) => {
      // punto existe en dataset -> borramos
      if (this.circles[d.name]) {
        this.map.removeLayer(this.circles[d.name]);
        delete this.circles[d.name];
      }
    });
  }

  removeAllPoints() {
    Object.keys(this.circles).forEach((key) => {
      this.map.removeLayer(this.circles[key]);
    });
  }

  onpoint(listener, fn) {
    this.pointevents[listener] = fn;
  }
}
