<template>
  <div class="input-contacts">
    <div class="input-contacts__contact" v-for="(contact, i) in value" :key="i">
      <div class="input-block__remove">
        <span @click="removeContact(i)" class="input-block__remove__text">&#215;</span>
      </div>
      <div class="flex">
        <div class="flex__middle">
          <InputText
            :name="`contactname-${i}`"
            :isDisabled="isDisabled"
            :isRequired="true"
            :label="$t('common.name')"
            v-model="value[i].name">
          </InputText>
        </div>
        <div class="flex__middle">
          <InputText
            :name="`contactrole-${i}`"
            :isDisabled="isDisabled"
            :isRequired="true"
            :label="$t('common.role')"
            v-model="value[i].role">
          </InputText>
        </div>
      </div>

      <div class="flex">
        <div class="flex__fourth">
          <InputEmail
            :name="`contactemail-${i}`"
            :isDisabled="isDisabled"
            :isRequired="true"
            :label="$t('common.email')"
            v-model="value[i].email">
          </InputEmail>
        </div>
        <div class="flex__fourth">
          <InputText
            :name="`contacttlf-${i}`"
            :isDisabled="isDisabled"
            :label="$t('common.tlf')"
            v-model="value[i].tlf">
          </InputText>
        </div>
        <div class="flex__fourth">
          <InputSocialPlatform
            :name="`contactplatform-${i}`"
            :isDisabled="isDisabled"
            :label="$t('common.platform')"
            v-model="value[i].platform"
          ></InputSocialPlatform>
        </div>
        <div class="flex__fourth">
          <InputText
            :name="`contacturl-${i}`"
            :isDisabled="isDisabled"
            :label="$t('common.url')"
            v-model="value[i].url"
            :runValidations="true"
            :validators="urlValidators">
            <span v-if="value[i].url && !urlValidators.every((v) => v(value[i].url))">
              {{$t('error.invalid_url')}}
            </span>
          </InputText>
        </div>
      </div>

    </div>

    <Button v-show="!isDisabled" :isDisabled="!canAddContact" @onclick="addContact()">
      <span v-if="value.length > 0">{{$t('common.add_another')}}</span>
      <span v-else>{{$t('common.add')}}</span>
    </Button>
  </div>
</template>

<script>
import Check from '@/services/check/Check';
import InputEmail from '@/components/inputs/email/InputEmail.vue';
import InputSocialPlatform from '@/components/inputs/social-platform/InputSocialPlatform.vue';
import InputMixin from '../InputMixin';

export default {
  name: 'InputContacts',
  components: {
    InputEmail,
    InputSocialPlatform,
  },
  mixins: [
    InputMixin,
  ],
  data() {
    return {
      urlValidators: [Check.isValidUrl],
    };
  },
  computed: {
    value: {
      get() {
        return Array.isArray(this.modelValue)
          ? this.modelValue
          : [];
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    canAddContact() {
      return true;
      // return !this.value.length;
    },
  },
  methods: {
    addContact() {
      this.value = [...this.value, {
        name: '',
        role: '',
        email: '',
        tlf: '',
        url: '',
        platform: '',
      }];
    },
    removeContact(i) {
      this.value.splice(i, 1);
    },
  },
};
</script>

<style lang="scss">
.input-contacts {
  &__contact {
    + .input-contacts__contact {
      border-top: 1px solid var(--border-color-dark);
      padding-top: 14px;
    }
  }
  .input-block .input-block__alerts {
    display: none;
  }
}
</style>
