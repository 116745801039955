<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired && !isDisabled"
    :isInvalid="!isValid"
    :classes="[
      'input-block--date',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <datepicker
      v-if="!isDisabled"
      v-model="date"
    ></datepicker>

    <DateTime
      v-if="isDisabled"
      :value="value"
      :format="displayFormat"
    ></DateTime>

    <template #alerts>
      <div v-if="!isValid">{{$t('error.invalid_date')}}</div>
      <slot></slot>
    </template>

  </InputBlock>
</template>

<script>
import datepicker from 'vue3-datepicker';
import InputProps from '../InputProps';
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputDate',
  mixins: [
    InputMixin,
  ],
  components: {
    InputBlock,
    datepicker,
  },
  props: {
    ...InputProps.props,
    displayFormat: {
      type: String,
      default: 'll',
    },
  },
  computed: {
    isValid() {
      return true;
    },
    date: {
      get() {
        return this.value ? new Date(this.value) : new Date();
      },
      set(value) {
        this.value = value;
      },
    },
  },
};
</script>

<style lang="scss">
.input-block--date .datetime {
  padding: 0;
  display: block;
  line-height: normal;
  white-space: nowrap;
  background-color: transparent;
  box-sizing: border-box;
  cursor: default;
}
</style>
