<template>
  <router-link :to="{ name: 'ranking', params: { locale: $i18n.locale, category: 'IBEX35' }}">
  <div class="ranking-banner">
    <img src="/img/ranking-banner.jpg" alt="">
  </div>
  </router-link>
</template>

<script>
export default {
  name: 'RankingBannerComponent',

};
</script>

<style lang="scss" scoped>
.ranking-banner {
  background-color: var(--background-color-darker-blue);
  display: flex;
  justify-content: center;
  img {
    height: auto;
    width: 100%;
    max-width: var(--layout-max-width-default);
  }
}

</style>
