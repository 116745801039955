<template>
  <div id="loadbar" class="loadbar" :class="{'is-active': isActive}">
    <div class="loadbar__bar" :style="{width: `${width}%`}"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Loadbar',
  data() {
    return {
      width: 0,
      isActive: false,
    };
  },
  computed: {
    ...mapGetters(['loadProgress']),
  },
  methods: {
    end() {
      this.place(100);
      this.isActive = false;
      setTimeout(() => {
        this.$store.dispatch('loadProgress', 0);
      }, 300);
    },
    place(val) {
      this.width = val;
    },
    go(dist) {
      if (this.loadProgress === 100 && dist < 0.1) {
        this.end();
      } else if (dist > 0.1) {
        this.move();
      }
    },
    move() {
      const dist = this.loadProgress - this.width;
      this.place(this.width + (dist / 4));
      setTimeout(() => {
        this.go(dist / 4);
      }, 16);
    },
  },
  watch: {
    loadProgress(val) {
      if (val < 0) return;

      if (val < this.width) {
        this.width = val;
      }
      this.isActive = true;
      this.move();
    },
  },
};
</script>

<style lang="scss">
$bar-height: 3px;

.loadbar {
  position: fixed;
  top: -$bar-height;
  left: 0;
  z-index: 999999;
  height: $bar-height;
  width: 100%;
  pointer-events: none;
  transition: top 100ms;
  &.is-active {
    top: 0;
  }
  &__bar {
    height: 100%;
    width: 0;
    background: var(--primary-color-default);
    background-size: 400% 100%;
    animation: gradient 3s linear infinite;
  }
}
</style>
