<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired && !isDisabled"
    :classes="[
      'input-block--textarea',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <textarea
      v-if="!isDisabled"
      :id="name"
      v-model="value"
      ref="input"
      :rows="rows"
      :placeholder="placeholder"
      :required="isRequired"
      @input="$emit('input', $event.target.value)"
    ></textarea>

    <div class="textarea-disabled" v-if="isDisabled">
      {{value}}
    </div>

    <template #alerts>
      <slot></slot>
    </template>

  </InputBlock>
</template>

<script>
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputTextArea',
  mixins: [
    InputMixin,
  ],
  components: {
    InputBlock,
  },
  props: {
    rows: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style lang="scss">
.textarea-disabled {
  white-space: pre-wrap;
}
</style>
