<template>
  <span class="currency">
    <span class="currency__value">
      {{value}}
    </span> <span class="currency__currency">
      {{curr(currency)}}
    </span>
  </span>
</template>

<script>
import Parser from '@/services/parser/Parser';

export default {
  name: 'Currency',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    currency: {
      type: String,
      required: false,
      default: 'eur',
    },
  },
  methods: {
    curr: Parser.currency,
  },
};
</script>
