module.exports = [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/components/users/UsersPage.vue'),
    meta: {
      isPublic: false,
    },
  },
];
