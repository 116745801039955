<template>
  <div class="block-section">
    <h3 class="block-section__title">
      <slot></slot>
    </h3>
    <img class="block-section__dots" src="/img/dots5.svg" alt="dots5">
  </div>
</template>

<script>
export default {
  name: 'BlockSection',
};
</script>

<style lang="scss">
.block-section {
  padding-top: 40px;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title {
    margin-bottom: 0;
    padding: 20px 20px;
    border-radius: 30px;
    color: var(--primary-color-default);
    background: transparent;
    font-size: 14px;
    font-weight: 600;
    text-transform: none !important;
  }
  &__dots {
    max-width: 40px;
  }
}
</style>
