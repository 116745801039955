module.exports = {
  models: {
    entity: {
      pagination: {},
      items: [],
      item: {
        last_report: {},
      },
    },

    report: {
      pagination: {},
      items: [],
      item: {
        year: null,
        networks: [],
        challenges: [],
        contacts: [],
        shares: [],
        certifications: [],
        goals: [],
        indicators: [],
      },
    },

    user: {
      pagination: {},
      items: [],
      item: {},
    },

    reportchallenge: {
      pagination: {},
      items: [],
      item: {},
    },

    stats: {
      count: undefined,
      field_stats: {
        year: {},
        contact: undefined,
        position: undefined,
        person: undefined,
        appointment: undefined,
        annual_report: undefined,
        positive_impact: undefined,
        improvable: undefined,
        itemized: undefined,
        budget: undefined,
        invest_program: undefined,
        aligned: undefined,
        localized: undefined,
        priority: undefined,
        priority_sdg: undefined,
        mlo: undefined,
        ilo: undefined,
        mlt: undefined,
        ilt: undefined,
        strategic_actions: undefined,
        corp_policy: undefined,
        action: undefined,
        internal_training: undefined,
        sensitivity_training: undefined,
        policy_changes: undefined,
        ods_page: undefined,
        ods_data_source: undefined,
        procedures: undefined,
        overseer_position: undefined,
        person_evaluation: undefined,
        appointment_evaluation: undefined,
        contact_evaluation: undefined,
      },
    },

    cnae: {
      pagination: {},
      items: [],
      item: {},
    },
  },

  getters: {
    entity: (state) => state.entity.item,
    entities: (state) => state.entity.items,
    entitiesPagination: (state) => state.entity.pagination,

    report: (state) => state.report.item,
    reports: (state) => state.report.items,
    reportsPagination: (state) => state.report.pagination,

    reportchallenge: (state) => state.reportchallenge.item,
    reportchallenges: (state) => state.reportchallenge.items,
    reportchallengesPagination: (state) => state.reportchallenge.pagination,

    user: (state) => state.user.item,
    users: (state) => state.user.items,
    usersPagination: (state) => state.user.pagination,

    getStats(state) { return state.stats; },

    cnae: (state) => state.cnae.item,
    cnaes: (state) => state.cnae.items,
    cnaesPagination: (state) => state.cnae.pagination,
  },
};
