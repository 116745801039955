<template>
  <div class="modal" :class="[ ...classes, {'is-active': isActive}]">
    <div class="modal__wrap">
      <div class="modal__wrapper">
        <div class="modal__content">
          <div class="modal__header">
            <slot name="header"></slot>
          </div>
          <div class="modal__body">
            <slot></slot>
          </div>
          <div class="modal__footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal__overlay" @click="$emit('overlay-click')"></div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  emits: ['overlay-click'],
  data() {
    return {
      isActive: false,
    };
  },
  props: {
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
  mounted() {
    setTimeout(() => {
      this.isActive = true;
    }, 50);
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  overflow-y: auto;
  background: var(--modal-overlay-mobile-color);

  &.is-active {
    .modal__overlay {
      opacity: 1;
    }
    .modal__content {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__overlay {
    background: var(--modal-overlay-mobile-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0;
    transition: opacity 250ms ease;
    pointer-events: none;
  }
  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    pointer-events: none;
    max-height: 100%;
  }
  &__content {
    width: 100%;
    opacity: 0;
    pointer-events: all;
    overflow-y: auto;
  }
  &__header {
    padding: 14px 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
  }
  &__body {
    padding: 20px;
  }
  &__footer {
    padding: 20px;
    text-align: right;
    line-height: 1.2;
    .btn + .btn {
      margin-left: 6px;
    }
  }
}

@media screen and (min-width: 460px) {
  .modal {
    &__content {
      min-width: 340px;
    }
  }
}

@media screen and (min-width: 520px) {
  .modal {
    &__content {
      margin: 0 auto;
      max-width: 480px;
    }
  }
}

@media screen and (min-width: 780px) {
  .modal {
    background: transparent;
    &__overlay {
      background-color: var(--modal-overlay-desktop-color);
      pointer-events: all;
    }
    &__header {
      padding: 18px 20px;
      background: var(--background-color-default);
      box-shadow: inset 0 -1px var(--border-color-dark);
    }
    &__body {
      padding: 18px 20px;
      background: var(--background-color-default);
      box-shadow: inset 0 -1px var(--border-color-dark);
    }
    &__content {
      max-width: 680px;
      border-radius: 4px;
      transform: translateY(60px);
      transition: opacity 250ms ease 100ms, transform 350ms cubic-bezier(0, 0, 0.13, 1);
      // box-shadow: 0 7px 14px 0 rgba(60,66,87,.08), 0 3px 6px 0 rgba(0,0,0,.12);
      box-shadow: none;
      overflow: visible;
    }
    &__footer {
      padding: 14px 20px;
      background: var(--background-color-default);
    }
    &--large {
      .modal__content {
        max-width: 920px;
      }
    }
  }
}

</style>
