module.exports = [
  {
    path: '/help',
    name: 'help',
    component: () => import('@/components/help/HelpPage.vue'),
    meta: {
      isPublic: true,
    },
  },
];
