<template>
  <div class="form" :class="[ ...classes]">

    <slot></slot>

    <div class="form__buttons">
      <slot name="buttons"></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Form',
  props: {
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss">
.form {
  &__buttons {
    margin-top: 12px;
    text-align: right;
    > button {
      margin-left: 8px;
      margin-right: 0;
    }
  }
  &--alert {
    border: 2px solid var(--error-color-default);
    padding: 20px 20px 10px;
    border-radius: 3px;
    .form-title:first-child {
      margin-top: 0;
      color: var(--error-color-default);
    }
  }
}
</style>
