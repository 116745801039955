<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired && !isDisabled"
    @click="onInputBlockClick"
    :classes="[
      'input-block--select',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <div
      class="inputselect"
      :class="{
        'is-disabled': isDisabled,
        'is-single': !isMultiple,
        'is-opened': isOpen,
        'has-focus': isFocused,
        'is-searching': `${searchValue}`.length > 0,
        'is-searchable': isSearchable,
      }">

      <!-- search input -->
      <div class="inputselect__head" :class="{'is-focused': isFocused}">
        <div class="inputselect__selected">
          <div
            v-for="(option, i) in selectedOptions"
            :key="i">
              <span v-if="!optionComponent">{{option.n}}</span>
              <span v-if="optionComponent">
                <component :is="optionComponent" v-bind="optionComponentAttrs(option)"/>
              </span>
              <i @click="onDeselect(option)"></i>
          </div>
        </div>
        <input
          type="text"
          ref="search"
          :placeholder="selectedOptions.length ? '' : placeholder"
          v-model.trim="searchValue"
          :disabled="!isSearchable"
          @focus="onFocus($event)"
          @blur="onBlur($event)"
          @mousedown="onSearchDown($event)"
          @keydown="onSearchKeyDown">
      </div>
      <!-- end search input -->

      <!-- dropdown -->
      <div class="inputselect__options" v-if="isOpen">
        <ul ref="options">
          <li
            v-for="(option, i) in filteredOptions"
            :key="i"
            @mouseover="isSelectable(option) ? typeAheadPointer = i : null"
            @mousedown.prevent.stop="isSelectable(option) ? select(option) : null"
            class="inputselect__option"
            :class="{
              'is-selected': isOptionSelected(option),
              'is-highlighted': i === typeAheadPointer,
              'is-disabled': !isSelectable(option),
            }">
            <span v-if="!optionComponent" :title="option.n">{{option.n}}</span>
            <span v-if="optionComponent">
              <component :is="optionComponent" v-bind="optionComponentAttrs(option)"/>
            </span>
          </li>
          <li
            v-if="filteredOptions.length === 0 && searchValue.length > 0"
            class="inputselect__option inputselect__option--empty">
            {{$t('common.no_select_results')}}
          </li>
        </ul>
      </div>
      <!-- end dropdown -->
    </div>

    <template #alerts>
      <slot></slot>
    </template>

    <template #link>
      <slot name="link"></slot>
    </template>

  </InputBlock>
</template>

<script>
import InputProps from '../InputProps';
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputSelect',
  mixins: [
    InputMixin,
  ],
  components: {
    InputBlock,
  },
  data() {
    return {
      isFocused: false,
      isOpen: false,
      typeAheadPointer: -1,
      searchValue: '',
      selectedOptions: [],
    };
  },
  props: {
    ...InputProps.props,
    options: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    isDynamic: {
      type: Boolean,
      default: false,
    },
    optionComponent: {
      type: String,
      default: '',
    },
    optionComponentAttrs: {
      type: Function,
      default: () => ({}),
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    filteredOptions() {
      const options = this.searchValue.length
        ? this.filter([...this.options], this.searchValue)
        : [...this.options];
      return options;
    },
  },
  mounted() {
    this.onModelValueChange();
  },
  methods: {
    filter(options, searchValue) {
      return this.isDynamic
        ? options
        : options
          .filter((option) => {
            const label = option.n ? `${option.n}`.toLowerCase() : '';
            return label.indexOf(searchValue.toLowerCase()) !== -1;
          });
    },
    onInputBlockClick() {
      if (!this.isDisabled) {
        this.$refs.search.focus();
        this.isFocused = true;
        this.isOpen = true;
      }
    },
    onFocus() {
      if (!this.isDisabled) {
        this.isFocused = true;
        this.isOpen = true;
        this.typeAheadPointer = -1;
      }
    },
    onBlur() {
      // Check if search is filled and if value is matched
      if (!this.isMultiple && `${this.searchValue}`.length > 0) {
        const idx = this.filteredOptions.map((o) => `${o.n}`).indexOf(`${this.searchValue}`);
        if (idx > -1) {
          this.select(this.filteredOptions[idx]);
        }
      }

      this.isFocused = false;
      this.isOpen = false;
      this.typeAheadPointer = 0;
    },
    onEscape() {
      this.isOpen = false;
      this.typeAheadPointer = 0;
      if (!this.isMultiple && this.selectedOptions.length === 1) {
        this.searchValue = '';
      }
    },
    onSearchDown() {
      this.isFocused = true;
      this.isOpen = true;
    },
    onDeselect(option) {
      this.selectedOptions = this.selectedOptions
        .filter((o) => o.v !== option.v);
    },
    isOptionSelected(option) {
      return this.selectedOptions.map((o) => o.v).indexOf(option.v) !== -1;
    },
    isSelectable(option) {
      return !option.disabled;
    },
    onTab() {
      if (this.filteredOptions.length === 1) {
        this.typeAheadSelect();
      }
    },
    onSupr() {
      if (!this.isMultiple && this.searchValue === '' && this.selectedOptions.length === 1) {
        this.selectedOptions = [];
        this.typeAheadPointer = -1;
        this.setModelValue();
        this.isOpen = false;
      }
    },
    onModelValueChange() {
      if (this.filteredOptions.length === 0 && this.modelValue) {
        this.select(this.modelValue);
      }
      if (!this.isMultiple) {
        if (this.modelValue) {
          const idx = this.filteredOptions.map((o) => `${o.v}`).indexOf(`${this.modelValue}`);
          if (idx > -1) {
            this.select(this.filteredOptions[idx]);
          }
        } else {
          this.searchValue = '';
          this.selectedOptions = [];
        }
      }
    },
    select(option) {
      if (this.isMultiple) {
        if (this.isOptionSelected(option)) return; // Prevent duplicates
        this.selectedOptions.push(option);
      } else {
        this.selectedOptions = [option];
      }

      this.setModelValue();
      this.isOpen = false;
      this.searchValue = '';
    },
    setModelValue() {
      if (!this.selectedOptions.length) {
        this.value = this.optionComponent ? {} : '';
        return;
      }
      if (this.optionComponent) {
        this.value = this.isMultiple
          ? this.selectedOptions
          : this.selectedOptions[0];
      } else {
        this.value = this.isMultiple
          ? this.selectedOptions.map((o) => o.v)
          : this.selectedOptions[0].v;
      }
    },
    maybeDeleteValue() {
      if (this.isMultiple) {
        console.log('maybeDeleteValue');
      } else {
        this.onSupr();
      }
    },
    maybeAdjustScroll() {
      const optionEl = this.$refs.options && this.$refs.options.children[this.typeAheadPointer]
        ? this.$refs.options.children[this.typeAheadPointer]
        : false;
      if (optionEl) {
        const bounds = this.getOptionsListViewport();
        const { top, bottom, height } = optionEl.getBoundingClientRect();
        if (top < bounds.top) {
          this.$refs.options.scrollTop = optionEl.offsetTop;
        } else if (bottom > bounds.bottom) {
          this.$refs.options.scrollTop = optionEl.offsetTop - (bounds.height - height);
        }
      }
    },
    getOptionsListViewport() {
      return this.$refs.options
        ? this.$refs.options.getBoundingClientRect()
        : { height: 0, top: 0, bottom: 0 };
    },
    onSearchKeyDown(e) { // eslint-disable-line
      const preventAndSelect = (e2) => {
        e2.preventDefault();
        return !this.isComposing && this.typeAheadSelect();
      };
      const defaults = {
        8: (/* backspace */) => this.maybeDeleteValue(),
        9: (/* tab */) => this.onTab(),
        16: (/* shift */) => () => null,
        17: (/* ctrl */) => () => null,
        18: (/* alt */) => () => null,
        20: (/* bloq mayus */) => () => null,
        225: (/* alt gr */) => () => null,
        27: (/* esc */) => this.onEscape(),
        38: (e2 /* up */) => {
          e2.preventDefault();
          return this.typeAheadUp();
        },
        40: (e2 /* down */) => { // eslint-disable-line
          e2.preventDefault();
          if (this.isOpen) {
            return this.typeAheadDown();
          }
          this.typeAheadPointer = -1;
          this.isOpen = true;
        },
        46: (/* supr */) => this.onSupr(),
      };
      // Keycodes that will select the current option. 13 -> Enter
      [13].forEach((keyCode) => {
        defaults[keyCode] = preventAndSelect;
      });
      if (typeof defaults[e.keyCode] === 'function') {
        return defaults[e.keyCode](e);
      }
      // If key is not mapped, show the options
      this.isFocused = true;
      this.isOpen = true;
    },
    typeAheadUp() {
      for (let i = this.typeAheadPointer - 1; i >= 0; i -= 1) {
        if (this.isSelectable(this.filteredOptions[i])) {
          this.typeAheadPointer = i;
          break;
        }
      }
    },
    typeAheadDown() {
      for (let i = this.typeAheadPointer + 1; i < this.filteredOptions.length; i += 1) {
        if (this.isSelectable(this.filteredOptions[i])) {
          this.typeAheadPointer = i;
          break;
        }
      }
    },
    typeAheadSelect() {
      const typeAheadOption = this.filteredOptions[this.typeAheadPointer];
      if (typeAheadOption) this.select(typeAheadOption);
    },
    findTypeHeadPosition() {
      if (this.searchValue) {
        const regex = new RegExp(`^${this.searchValue}`, 'gi');
        const index = this.filteredOptions.findIndex((o) => `${o.n}`.match(regex));
        if (index) {
          this.typeAheadPointer = index;
          return;
        }
      }
      for (let i = 0; i < this.filteredOptions.length; i += 1) {
        if (this.isSelectable(this.filteredOptions[i])) {
          this.typeAheadPointer = i;
          break;
        }
      }
    },
  },
  watch: {
    modelValue: 'onModelValueChange',
    typeAheadPointer: 'maybeAdjustScroll',
    filteredOptions: 'findTypeHeadPosition',
    searchValue(val) {
      if (val && !this.isDisabled) {
        this.isFocused = true;
        this.isOpen = true;
      }
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss">
.input-block .inputselect {
  position: relative;

  &.is-disabled {
    pointer-events: none;
    input[type="text"] {
      display: none;
    }
    .inputselect__head {
      &:after {
        display: none;
      }
    }
  }

  &.is-searchable {
    input[type="text"] {
      pointer-events: all !important;
    }
  }

  &__head {
    position: relative;
    width: 100%;
    min-height: 36px;
    white-space: nowrap;
    border: 1px solid var(--border-color-default);
    border-radius: 4px;
    background-color: transparent;
    transition: box-shadow 250ms ease, border-color 250ms ease;
    outline: 0;
    overflow: hidden;
    box-shadow: 0 0 0 3px transparent;
    box-sizing: border-box;
    line-height: normal;
    display: flex;

    &:after {
      content: "";
      width: 8px;
      display: block;
      background-image: url(/img/icons/select/down.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      pointer-events: none;
      margin-right: 6px;
    }

    &.is-focused {
      outline: 0;
      box-shadow: 0 0 0 3px var(--box-shadow-color-focus);
      border-color: var(--border-color-focus);
    }

    input[type="text"] {
      flex: 1 1 100%;
      padding: 8px 12px;
      min-height: initial;
      white-space: initial;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      transition: none;
      outline: 0 !important;
      box-shadow: none !important;
      width: inherit;
      min-width: unset;
      pointer-events: none;
    }
  }

  &__options {
    position: absolute;
    z-index: 999;
    background: var(--background-color-default);
    width: 100%;
    left: 0;
    top: calc(100% - 1px);
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.15);
    border: 1px solid var(--border-color-default);
    border-top-style: none;
    border-radius: 0 0 4px 4px;
    ul {
      width: 100%;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  &__option {
    padding: 5px 12px;
    white-space: pre;
    &.is-highlighted {
      color: var(--highlight-color-default);
      background-color: var(--highlight-background-color-default);
    }
    &--empty {
      color: #858998;
      font-style: italic;
      text-align: center;
    }
  }

  &__selected {
    flex: 0 0 auto;
    > div {
      background-color: #e3e8ee;
      color: rgb(79, 86, 107);
      font-weight: 500;
      padding: 5px 0 5px 7px;
      white-space: pre;
      line-height: 1;
      border-radius: 3px;
      margin: 5px 0 0 5px;
      i {
        width: 10px;
        height: 10px;
        display: inline-block;
        background-image: url('/img/icons/select/remove.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        padding: 0 10px 0 5px;
        cursor: pointer;
      }
    }
  }

  &.is-single {
    .inputselect__selected > div {
      position: absolute;
      top: 0;
      left: 0;
      // z-index: -1; // Comented due to not working fine on modals
      color: inherit;
      background: transparent;
      margin: 0;
      padding: 8px 12px 8px;
      font-weight: inherit;
      width: 100%;
      box-sizing: border-box;
      span {
        line-height: normal;
      }
      i {
        display: none;
      }
    }
    &.is-opened .inputselect__selected > div {
      color: #858998;
    }
    &.is-searching .inputselect__selected > div {
      span {
        display: none;
      }
    }
  }
}
</style>
