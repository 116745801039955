/**
 * GeneratorService class
 */
export default class {
  static years(minYear = 2010, maxYear = null) {
    const years = [];
    const startyear = maxYear !== null ? maxYear : new Date().getFullYear();
    for (let i = startyear; i >= minYear; i -= 1) {
      years.push({ v: i, n: i });
    }
    return years;
  }

  static months(groubBy = null) {
    const months = [];
    if (groubBy > 1) {
      for (let i = 12 / Math.floor(12 / groubBy); i > 0; i -= 1) {
        months.push({ v: `${i}/${groubBy}`, n: `months.${i}/${groubBy}` });
      }
    }
    return [
      ...months,
      { v: 12, n: 'months.december' },
      { v: 11, n: 'months.november' },
      { v: 10, n: 'months.october' },
      { v: 9, n: 'months.september' },
      { v: 8, n: 'months.august' },
      { v: 7, n: 'months.july' },
      { v: 6, n: 'months.june' },
      { v: 5, n: 'months.may' },
      { v: 4, n: 'months.april' },
      { v: 3, n: 'months.march' },
      { v: 2, n: 'months.february' },
      { v: 1, n: 'months.january' },
    ];
  }

  static trueFalse(trueLabel, falseLabel) {
    return [{ n: trueLabel, v: 'true' }, { n: falseLabel, v: 'false' }];
  }

  static get nameSort() {
    return (a, b) => {
      if (a.n > b.n) {
        return 1;
      }
      if (a.n < b.n) {
        return -1;
      }
      return 0;
    };
  }
}
