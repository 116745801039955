<template>
  <div class="banner">
    <div class="banner__wrap flex flex--wrap">
      <img class="banner__logo flex__middle" src="/img/banner.svg" alt="OpenODS">
      <div class="banner__text flex__middle">
        <h1>{{$t('home.title')}}</h1>
        <p v-html="$t('home.desc')"></p>
        <div>
          <Button
            v-if="$route.name !== 'info'"
            :classes="['btn--banner']"
            @onclick="$router.push({name: 'info', params: {locale: $i18n.locale } })">
            {{$t('common.see_system')}}
          </Button>
          <Button
            :classes="['btn--banner-accent']"
            @onclick="$router.push({name: 'home', params: {locale: $i18n.locale }, hash: '#results' })">
            {{$t('common.explore')}}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerComponent',
};
</script>

<style lang="scss">
.banner {
  text-align: center;
  background-color: var(--background-color-darker-blue);
  &__logo {
    width: 200px;
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  &__text {
    color: var(--text-color-inverted);
    text-align: start;
    h1 {
      text-transform: none;
    }
    p {
      font-size: 18px;
      font-family: var(--font-family-info);
      font-weight: normal;
      font-style: normal;
    }
  }
  &__wrap {
    margin: auto;
    padding: 80px 10px 50px 10px;
    max-width: var(--layout-max-width-narrow);
  }
}

@media screen and (min-width: 512px) {
  .banner {
    margin: 0 0 80px;
  }
}

@media screen and (min-width: 768px) {
  .banner {
    margin: 0 0 40px;
    &__text {
      margin: 30px auto 10px;
      max-width: 600px;
    }
  }
}
</style>
