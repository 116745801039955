/* eslint-disable class-methods-use-this */

/**
 * ApiService class
 */
class ApiService {
  url(instanceModel, instanceId = undefined, instanceSlug = undefined) {
    const base = process.env.VUE_APP_API_URL;
    const api = 'api/v2/';
    const id = instanceId ? `${instanceId}/` : '';
    const slug = instanceSlug ? `${instanceSlug}/` : '';

    if (slug) {
      return `${base}${api}entities/slug/${slug}`;
    }

    switch (instanceModel) {
      /* eslint-disable no-multi-spaces */

      // Auth
      case 'login':                             return `${base}jwt/login/`;
      case 'logout':                            return `${base}jwt/logout/`;
      case 'refresh':                           return `${base}jwt/refresh/`;
      case 'register':                          return `${base}${api}auth/register/`;
      case 'activate':                          return `${base}${api}auth/activate/`;
      case 'recoverPassword':                   return `${base}${api}auth/request_password_reset/`;
      case 'resetPassword':                     return `${base}${api}auth/set_password/`;

      // Apps' models
      case 'user':                              return `${base}${api}users/${id}`;
      case 'entity':                            return `${base}${api}entities/${id}`;
      case 'report':                            return `${base}${api}reports/${id}`;
      case 'reportchallenge':                   return `${base}${api}reportchallenges/${id}`;
      case 'cnae':                              return `${base}${api}cnaes/${id}`;

      case 'stats':                             return `${base}${api}stats/`;

      // Actions
      case 'self':                              return `${base}${api}users/self/`;
      case 'checkentityvat':                    return `${base}${api}entities/checkvat/`;
      case 'checkentityslug':                   return `${base}${api}entities/checkslug/`;
      case 'entitypublish':                     return `${base}${api}entities/${id}publish/`;
      case 'contact':                           return `${base}${api}users/contact/`;

      // Default
      default:                                  return `${base}${api}${instanceModel}/${id}`;
      /* eslint-enable no-multi-spaces */
    }
  }

  /**
   * Get headers for requests
   * - If the access token exists, return Auth Bearer,
   *   else send cookies with the request
   */
  getHeaders() {
    const token = this.getToken();
    return token
      ? { headers: { Authorization: `Bearer ${token}` } }
      : { headers: {}, withCredentials: true };
  }

  clearToken() {
    localStorage.removeItem('accessToken');
  }

  setToken(access) {
    if (access) {
      localStorage.setItem('accessToken', access);
    } else {
      this.clearToken();
    }
  }

  getToken() {
    return localStorage.getItem('accessToken');
  }
}

const API = new ApiService();

export default API;
