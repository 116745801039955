<template>
  <div>
    <BlockSubtitle modal="treemap">{{title}}</BlockSubtitle>
    <transition-group tag="div" name="barfade"  class="treemap">
      <div
        class="treemap__box"
        v-for="sdg in finalSdgs"
        :style="{
          left: `${sdg.x0}%`,
          top: `${sdg.y0}%`,
          width: `${sdg.x1 - sdg.x0}%`,
          height: `${sdg.y1 - sdg.y0}%`,
          backgroundColor: sdg.data.color,
          backgroundImage: `url(/img/${$i18n.locale}/sdg/${sdg.data.key}.png)`,
        }"
        :title="getLeaveTitle(sdg)"
        :key="sdg.data.key">
        <span>
          {{'sdg.data.ods.short_name[$i18n.locale]'}}
        </span>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { hierarchy, treemap } from 'd3-hierarchy';
import { sum } from 'd3-array';

const d3 = { hierarchy, treemap, sum };

export default {
  name: 'TreeMap',
  data() {
    return {
      finalSdgs: [],
    };
  },
  props: {
    results: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  created() {
    this.calculeTree(this.results);
  },
  computed: {
    ...mapGetters(['goals']),
  },
  methods: {
    calculeTree(data) {
      /*
      // Count number of times each sdg is on each report as {SDG_Code: times}
      // data = {"1": 12, "3": 1, "12": 3}
      // Used when results were an reports array
      const data = reports
        .reduce((acc, report) => acc.concat(report.goals.map((goal) => goal.global_goal)), [])
        .reduce((acc, sdg) => {
          if (Object.prototype.hasOwnProperty.call(acc, sdg)) acc[sdg] += 1;
          else acc[sdg] = 1;
          return acc;
        }, {});
      */

      // Construct hierarchical data
      const sdgs = this.goals.filter((g) => g.type === 'GOAL');
      const root = d3.hierarchy({
        children: Object.keys(data).map((key) => ({
          key,
          color: sdgs.find((goal) => +goal.code === +key).color,
          value: data[key],
        })),
      }).sum((d) => d.value);

      d3.treemap()
        .size([100, 100])
        .padding(0)(root);

      this.finalSdgs = root.leaves();
    },
    getLeaveTitle(sdg) {
      const sdgTitle = this.$t(`sdg_name.${sdg.data.key}`);
      return `${sdgTitle}: ${this.$t('common.$count_entities', { count: sdg.value })}`;
    },
  },
  watch: {
    results(results) {
      this.calculeTree(results);
    },
  },
};
</script>

<style lang="scss" scoped>
.treemap {
  position: relative;
  height: 300px;
  max-width: var(--layout-max-width-default);
  margin: 0 auto;

  &__box {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid white;
    box-sizing: border-box;
    overflow: hidden;
    transition: left 500ms ease, top 500ms ease, height 500ms ease, width 500ms ease;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    span {
      color: white;
      font-size: 12px;
      font-weight: 600;
      display: block;
      line-height: 1;
      text-align: center;
      cursor: default;
      display: none;
    }
  }
}
</style>
