import dayjs from 'dayjs';
import marked from 'marked';
import DOMPurify from 'dompurify';

const localizedFormat = require('dayjs/plugin/localizedFormat');

dayjs.extend(localizedFormat);
dayjs.locale('es');

export default class Parser {
  /**
   * Converts currency code (eur) to currency symbol (€)
   */
  static currency(currencyCode) {
    switch (currencyCode.toLowerCase()) {
      /* eslint-disable no-multi-spaces */
      case 'eur':   return '€';
      case 'usd':   return '$';
      default:      return currencyCode;
      /* eslint-enable no-multi-spaces */
    }
  }

  /**
   * Converts country (any) to country code (es)
   */
  static country(country, countryList = []) {
    if (typeof country === 'string') {
      return country;
    }
    if (typeof country === 'object' && country.code) {
      return country.code;
    }
    if (typeof country === 'number') {
      const found = countryList.find((c) => c.id === country);
      return found ? found.code : country;
    }
    return null;
  }

  /**
   * Prettify VAT Number
   */
  static prettyVAT(value) {
    return value
      ? value
        .replace(/([a-z])(-)(\d{3,})/gi, '$1$3') // 13245678-B
        .replace(/(\d{3,})(-)([a-z])/gi, '$1$3') // A-12345678
        .toUpperCase()
      : '';
  }

  /**
   * Prettify Slug
   */
  /* eslint-disable */
  static prettySlug(value) {
      let str
      str = value
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      const to   = "aaaaeeeeiiiioooouuuunc------";
      for (let i=0, l=from.length ; i<l ; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-') // collapse dashes
          .replace(/-$/, '') // remove trailing dash
          .replace(/^-/, ''); // remove initial dash

      return str;
  }

  /**
   * Convert date to given format
   */
  static date(value, format) {
    const date = dayjs(value);
    return date.format(format);
  }

  /**
   * Sanitize html content
   */
  static sanitize(str) {
    return DOMPurify.sanitize(str);
  }

  /**
   * Convert markdown string to html
   */
  static mdMark(str) {
    return DOMPurify.sanitize(marked(str));
  }
}
