<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired && !isDisabled"
    :isInvalid="!isValid"
    :isDisabled="isDisabled"
    :classes="[
      'input-block--text',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <input
      :id="name"
      v-model="value"
      ref="input"
      type="text"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :required="isRequired"
      @input="$emit('input', $event.target.value)"
      @keyup.enter="$emit('enter', value)">

    <template #alerts>
      <slot></slot>
    </template>

    <template #link>
      <slot name="link"></slot>
    </template>

  </InputBlock>
</template>

<script>
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputText',
  mixins: [
    InputMixin,
  ],
  components: {
    InputBlock,
  },
};
</script>
