<template>
  <div class="chip" :class="[...classes]">

    <div class="chip__thumbnail" @click="onThumbClick" v-if="thumbnail || letter">
      <div v-if="thumbnail" :style="{backgroundImage: `url(${thumbnail})`}"></div>
      <div class="nothumb" v-else :style="{ backgroundColor: color }">{{letter}}</div>
      <div class="chip__thumbnail-back">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
          <path d="M 11.077191,28.242642 25.029571,41.561788 43.028148,15.610049
          40.79575,14.09963 24.750523,31.675411 14.425762,25.496426 Z"/></svg>
      </div>
    </div>

    <div class="chip__body" @click="onBodyClick">
      <slot></slot>
    </div>

    <div class="chip__side">
      <time
        v-if="date"
        @click="onSideClick"
        :datetime="date"
        :title="dateFormat(date, 'llll')">
        {{chipDate}}
      </time>
    </div>

  </div>
</template>

<script>
import Parser from '@/services/parser/Parser';

export default {
  name: 'Chip',
  emits: [
    'thumb-click',
    'body-click',
  ],
  props: {
    thumbnail: {
      type: [String, null],
      default: null,
    },
    letter: {
      type: String,
      required: true,
      default: '',
    },
    color: {
      type: String,
      default: undefined,
    },
    date: {
      default: null,
    },
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    chipDate() {
      return new Date().getFullYear() === new Date(this.date).getFullYear()
        ? this.dateFormat(this.date, 'D MMM')
        : this.dateFormat(this.date, 'D/MM/YYYY');
    },
  },
  methods: {
    dateFormat: Parser.date,
    onThumbClick(e) {
      this.$emit('thumb-click', e);
    },
    onBodyClick(e) {
      this.$emit('body-click', e);
    },
  },
};
</script>

<style lang="scss">
.chip {
  display: flex;
  width: 100%;

  &__thumbnail {
    flex: 0 0 50px;
    position: relative;
    > div {
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      width: 42px;
      height: 42px;
      line-height: 42px;
      border-radius: 100%;
      text-align: center;
      font-size: 28px;
      color: var(--text-color-inverted);
      font-weight: 500;
      letter-spacing: 0;
      backface-visibility: hidden;
      transition: transform 250ms ease;
      &.nothumb {
        background-color: var(--background-color-darker);
      }
      &.chip__thumbnail-back {
        transform: rotateY(180deg);
        background-color: var(--primary-color-default);
        svg {
          transform: scale(0.5);
          transition: transform 250ms cubic-bezier(0.6, -0.28, 0.74, 0.05) 10ms;
          width: 100%;
          height: 100%;
          fill: var(--background-color-default);
        }
      }
    }
  }

  &__body {
    flex: 1 1 auto;
    max-width: calc(100% - 58px);
    line-height: 1.1;
    min-width: 100px;
  }

  &__info {
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
    &--primary {
      font-weight: 700;
      word-break: break-word;
      /* white-space: pre; */
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &--extra {
      display: none;
    }
    &--spaced {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    > span {
      + span:before {
        content: ', ';
      }
      .country {
        display: inline-block;
      }
    }
  }

  &__side {
    flex: 0 1 32px;
    time {
      text-align: right;
      font-size: 12px;
      text-transform: lowercase;
      white-space: pre;
      color: var(--text-color-lighter);
      vertical-align: top;
      font-weight: 500;
      line-height: 1;
    }
  }

  .status {
    padding: 3px 6px;
    font-size: 12px;
  }

  /* Thumb (only image) size modifier */
  &--thumb {
    .chip__body {
      display: none;
    }
  }

  /* Row size modifier */
  &--row {
    .chip__info {
      margin-bottom: 0;
    }
    .chip__info--primary {
      margin-top: 3px;
    }
    .chip__info--secondary,
    .chip__info--tertiary {
      display: none;
    }
    .chip__thumbnail {
      flex: 0 0 28px;
      > div {
        margin-top: 0;
        width: 21px;
        height: 21px;
        line-height: 21px;
        font-size: 12px;
      }
    }
  }

  /* Narrow size modifier */
  &--narrow {
    .chip__info--tertiary {
      display: none;
    }
    .chip__thumbnail {
      flex: 0 0 50px;
      > div {
        margin-top: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        font-size: 26px;
      }
    }
    .chip__body {
      max-width: calc(100% - 50px);
    }
  }

  /* Full size modifier */
  &--full {
    .chip__extra {
      display: block;
      margin-left: -30px;
    }
  }

  /* selected status modifier */
  &--selected {
    .chip__thumbnail > div {
      transform: rotateY(180deg);
      &.chip__thumbnail-back {
        transform: rotateY(360deg);
        svg {
          transform: scale(0.8);
        }
      }
    }
  }
}

@media screen and (min-width: 780px) {
  .chip {
    &__info {
      &--primary {
        max-width: 240px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .chip {
    &__info {
      &--primary {
        max-width: 300px;
      }
    }
  }
}

/**
 * CHIP & TABLES
 *
 */
.table .table__chip .chip {
  &__thumbnail {
    flex: 0 0 42px;
    > div {
      width: 34px;
      height: 34px;
      line-height: 34px;
      font-size: 18px;
    }
  }
}

@media screen and (min-width: 780px) {
  .table .table__chip .chip {
    .chip__side,
    .chip__info--secondary,
    .chip__info--tertiary {
      display: none;
    }
    .chip__info--primary {
      margin-top: 3px;
    }
    .chip__thumbnail {
      flex: 0 0 27px;
      > div {
        margin-top: 0;
        width: 21px;
        height: 21px;
        line-height: 21px;
        font-size: 12px;
      }
    }
  }
}
</style>
