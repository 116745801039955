import { createStore } from 'vuex';
import Universe from './universe';
import Generics from './generics';
import Meeseeks from './meeseeks';
import Loadbar from './loadbar';
import Auth from './auth';
import Apps from './apps';

export default createStore({
  modules: {
    Universe,
    Generics,
    Meeseeks,
    Loadbar,
    Auth,
    Apps,
  },
});
