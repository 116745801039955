<template>
  <div class="input-goals">

    <div v-for="(goal, i) in value" :key="i">
      <InputSelect
        v-if="universeIsLoaded"
        :name="`goal-${i}`"
        v-model="value[i].global_goal"
        :isDisabled="!isEditing(i)"
        :label="isEditing(i) ? $t('common.goal') : ''"
        :options="subGoalsList">
        <template #link>
          <a v-if="!isEditing(i)" @click="editThisRow(i)">
            {{$t('common.edit')}}
          </a>
          <a v-if="isEditing(i)" v-show="value[i].global_goal" @click="closeThisRow(i)">
            {{$t('common.save')}}
          </a>
          <a class="input-block__remove__link" v-if="!isEditing(i)" @click="removeGoal(i)">
            {{$t('common.remove')}}
          </a>
        </template>
      </InputSelect>
    </div>

    <Button v-show="!isDisabled" :isDisabled="!canAddGoal" @onclick="addGoal()">
      <span v-if="value.length > 0">{{$t('common.add_another')}}</span>
      <span v-else>{{$t('common.add')}}</span>
    </Button>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InputMixin from '../InputMixin';

export default {
  name: 'InputGoals',
  mixins: [
    InputMixin,
  ],
  data() {
    return {
      editing: [],
    };
  },
  computed: {
    ...mapGetters(['universeIsLoaded', 'goals']),
    value: {
      get() {
        return Array.isArray(this.modelValue)
          ? this.modelValue
          : [];
      },
      set(value) {
        this.$emit('update:modelValue', value.map((g) => ({
          global_goal: g.global_goal,
          local_goal: g.global_goal,
          type: 'SUBGOAL',
        })));
      },
    },
    subGoalsList() {
      const getGoalName = (code) => this.$t(`goals.${code.replaceAll('.', '-')}`);
      return this.goals
        .filter((sdg) => sdg.type === 'SUBGOAL')
        .map((g) => ({
          v: +g.id,
          n: `${g.code} ${getGoalName(g.code)}`,
        }));
    },
    canAddGoal() {
      return !this.value.some((i) => i.global_goal === '');
    },
  },
  methods: {
    addGoal() {
      this.value = [...this.value, {
        global_goal: '',
        local_goal: '',
        type: 'SUBGOAL',
      }];
      this.editing = [this.value.length];
    },
    removeGoal(i) {
      this.value.splice(i, 1);
    },
    isEditing(idx) {
      return this.editing.indexOf(idx) > -1;
    },
    editThisRow(idx) {
      this.editing.push(idx);
    },
    closeThisRow(idx) {
      this.editing = this.editing.filter((id) => id !== idx);
    },
  },
};
</script>

<style lang="scss">
.input-goals {
  .input-block {
    padding-top: 0;
    padding-bottom: 0;
    .input-block__alerts {
      display: none;
    }
    .inputselect.is-disabled .inputselect__head .inputselect__selected > div {
      padding: 0;
      padding-right: 50px;
      overflow: hidden;
      span {

      }
    }
  }
  .btn {
    margin-top: 20px;
  }
}
</style>
