<template>
  <div class="sha-block">
    <div>
      <span>{{ $t("common.share_it") }}</span>
    </div>
    <div class="sha">
      <div
        style="background-image: url(/img/icons/social/twitter-white.svg)"
        :title="$t('common.share_on_$social', { social: 'Twitter' })"
        @click="shareOnTwitter"
      ></div>
      <div
        style="background-image: url(/img/icons/social/facebook-white.svg)"
        :title="$t('common.share_on_$social', { social: 'Facebook' })"
        @click="shareOnFacebook"
      ></div>
      <div
        style="background-image: url(/img/icons/social/linkedin-white.svg)"
        :title="$t('common.share_on_$social', { social: 'Linkedin' })"
        @click="shareOnLinkedin"
      ></div>
    </div>
  </div>
</template>

<script>
import UrlService from '@/services/url/UrlService';

const urlService = new UrlService();
export default {
  name: 'ShareSocialComponent',

  methods: {
    shareOnTwitter() {
      urlService.shareOnTwitter();
    },
    shareOnFacebook() {
      urlService.shareOnFacebook();
    },
    shareOnLinkedin() {
      urlService.shareOnLinkedin();
    },
  },
};
</script>

<style lang="scss" scoped>
.sha-block {
    margin: 50px auto;
    text-align: center;
    color: var(--text-color-blue);
    font-weight: bold;
}
.sha {
  text-align: center;
  padding: 20px 20px;
  span {
    margin-right: 10px;
  }
  div {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    cursor: pointer;
    transition: opacity 250ms ease;
    background-color: var(--primary-color-default);
    padding: 5px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    background-size: 15px 15px;
    background-position: center;
    background-repeat: no-repeat;
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
