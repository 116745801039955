<!-- eslint-disable max-len -->
<template>
  <div class="crumb crumb--profile" @click="isActive = !isActive">
    <svg
      class="profileimg"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="43.028px"
      height="43.028px"
      viewBox="0 0 43.028 43.028">
      <path class="userpath" d="m 39.561,33.971 -0.145,0.174 c -4.774,5.728 -11.133,8.884 -17.902,8.884 -6.77,0 -13.128,-3.155 -17.903,-8.884 L 3.467,33.971 3.501,33.748 C 4.423,27.734 7.565,22.903 12.348,20.142 l 0.34,-0.196 0.271,0.284 c 2.259,2.37 5.297,3.674 8.554,3.674 3.257,0 6.295,-1.305 8.554,-3.674 l 0.271,-0.284 0.34,0.196 c 4.783,2.761 7.925,7.592 8.848,13.606 z M 21.514,21.489 c 5.924,0 10.744,-4.82 10.744,-10.744 C 32.258,4.821 27.438,0 21.514,0 15.59,0 10.77,4.821 10.77,10.744 c 0,5.923 4.82,10.745 10.744,10.745 z" />
    </svg>

    <div class="crumb__menu" :class="{'is-active': isActive}">
      <ul class="crumb__menu-list">
        <li class="crumb__menu-item" v-if="userIsLogged && self.entities?.length">
          <router-link :to="getRoute()">{{$t('common.see_entity')}}</router-link>
        </li>
        <li class="crumb__menu-item" v-if="userIsLogged">
          <router-link :to="{name: 'logout', params: {locale: $i18n.locale}}">{{$t('common.logout')}}</router-link>
        </li>
        <li class="crumb__menu-item" v-if="!userIsLogged">
          <router-link :to="{name: 'login', params: {locale: $i18n.locale}}">{{$t('common.login')}}</router-link>
        </li>
      </ul>
    </div>

    <teleport to="#app">
      <Overlay v-if="isActive" @close="isActive = false"></Overlay>
    </teleport>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UrlService from '@/services/url/UrlService';

const urlService = new UrlService();

export default {
  name: 'CrumbsProfile',
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    ...mapGetters(['userIsLogged', 'self']),
  },
  methods: {
    getRoute() {
      return urlService.entityRoute(this.self.entities[0], this.$i18n.locale)
    }
  }
};
</script>

<style lang="scss">
.profileimg {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0.8);

  .userpath {
    transition: stroke 200ms ease;
    fill: transparent;
    stroke: var(--primary-color-strong);
    stroke-width: 2px;
  }

  &:active {
    .userpath {
      opacity: 0.7;
    }
  }
}
</style>
