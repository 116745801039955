import Croppie from 'croppie';

/**
 * ImageService class
 */
export default class {
  cropper = null;

  detail = null;

  str64 = null;

  events = {
    update: () => {},
  };

  constructor(outputElement, cropperOptions) {
    this.output = outputElement;
    this.options = cropperOptions;

    if (this.output.src) {
      // Only load cropper if image is setted;
      this.loadCropper();
    }
  }

  on(listener, fn) {
    this.events[listener] = fn;
  }

  loadCropper() {
    return new Promise((resolve) => {
      this.cropper = new Croppie(this.output, {
        viewport: { width: 200, height: 200 },
        showZoomer: false,
        ...this.options, // http://foliotek.github.io/Croppie/#documentation
      });
      this.cropper.element.addEventListener('update', (ev) => {
        if (!this.detail) {
          this.detail = JSON.stringify(ev.detail);
        } else if (this.detail !== JSON.stringify(ev.detail)) {
          this.events.update({ ...ev.detail });
        }
      });
      resolve();
    });
  }

  loadImage(file) {
    return new Promise((resolve) => {
      if (typeof file === 'string') {
        // file is url
        this.output.src = file;
        this.afterImageLoad(this.output.src);
        resolve();
      } else if (typeof file === 'object') {
        // file is from input type file
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.str64 = e.target.result;
          this.output.src = URL.createObjectURL(file);
          this.output.onload = () => {
            this.afterImageLoad(this.output.src);
            resolve({ blob: this.blob, base64: this.str64 });
          };
        };
      }
    });
  }

  afterImageLoad(url) {
    if (!this.cropper) {
      this.loadCropper().then(() => {
        this.cropper.bind({ url });
      });
    } else {
      this.cropper.bind({ url });
    }
  }

  get str64() {
    return this.str64;
  }

  getBase64() {
    return new Promise((resolve, reject) => {
      this.cropper.result({
        type: 'base64',
        format: 'webp',
      }).then((r) => {
        resolve(r);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  destroy() {
    this.cropper.destroy();
  }

  /**
   * Convert image url to convenience format
   */
  static imageUrl(image) {
    if (!image) return null;
    if (image.startsWith('/')) {
      return `${process.env.VUE_APP_API_URL}${image}`;
    }
    if (image.startsWith('http')) {
      const splittedUrl = image.split('/media/');
      return `${process.env.VUE_APP_API_URL}media/${splittedUrl[1]}`;
    }
    return `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_MEDIA_ROOT}${image}`;
  }
}
