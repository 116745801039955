<template>
  <InputSelect
    :name="name"
    :classes="classes"
    :label="label"
    :help="help"
    :isDisabled="isDisabled"
    :isRequired="isRequired"
    :focusOnMount="focusOnMount"
    v-model="value"
    :options="months"
  ></InputSelect>
</template>

<script>
import GeneratorService from '@/services/generator/GeneratorService';
import InputMixin from '../InputMixin';

export default {
  name: 'InputMonth',
  mixins: [
    InputMixin,
  ],
  props: {
    groupBy: {
      type: Number,
      required: false,
    },
  },
  computed: {
    months() {
      return GeneratorService.months(this.groupBy)
        .map((m) => ({ ...m, n: this.$t(m.n) }));
    },
  },
};
</script>
