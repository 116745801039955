<template>
  <div class="block-title">

    <h1 class="block-title__title">
      <slot></slot>
    </h1>

    <div class="block-title__buttons" v-if="buttons.length > 0">
      <button
        :key="button"
        v-for="button in buttons"
        :class="`block-title__button block-title__button--${button}`"
        :style="{backgroundImage: `url(/img/icons/blocktitle/${button}.svg)`}"
        @click="$emit(`on-${button}`)">{{$t(`blocktitle.${button}`)}}</button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BlockTitle',
  emits: ['on-add', 'on-edit', 'on-cancel', 'on-exit'],
  props: {
    buttons: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss">
$button-size: 24px;

.block-title {
  display: flex;
  align-items: center;

  &__title {
    flex: 1 1 100%;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    font-family: var(--font-family-display);
    font-weight: 600;
    text-transform: uppercase;
  }

  &__buttons {
    flex: 0 0 auto;
    line-height: 0;
  }

  &__button {
    display: inline-block;
    margin-bottom: 0;
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    color: white;
    background: var(--primary-color-default);
    font-size: 14px;
    font-weight: 600;
    text-transform: none !important;
    + .block-title__button {
      margin-left: 10px;
    }
    &:hover {
      background-color: #9299a0;
    }
    &:focus {
      outline: 0;
    }

    &--exit {
      background-color: var(--error-color-default);
      &:hover {
        background-color: var(--error-color-strong);
      }
    }

    &--edit {
      background-color: var(--primary-color-default);
      &:hover {
        background-color: var(--primary-color-default);
      }
    }

    &--add {
      background-color: var(--success-color-default);
      &:hover {
        background-color: var(--success-color-strong);
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .block-title {
    &__button {
      + .block-title__button {
        margin-left: 12px;
      }
    }
  }
}
</style>
