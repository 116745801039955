<template>
  <svg
    :width="size"
    :height="size"
    viewBox="-2 -2 42 42"
    xmlns="http://www.w3.org/2000/svg"
    :stroke="color"
    :class="[ ...classes, 'spinner' ]">
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)" stroke-width="2">
        <path d="M36 18c0-9.94-8.06-18-18-18" :style="{ strokeWidth: `${stroke}px` }">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"/>
        </path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    color: {
      type: String,
      default: '#087be3',
    },
    size: {
      type: Number,
      default: 46,
    },
    stroke: {
      type: Number,
      default: 2,
    },
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style lang="scss">
.spinner {
  &__wrap {
    text-align: center;
    margin: 30px auto;
  }
  &--center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
