const defaultTime = 4000;
const parseMessage = ({ message, type, time }) => ({
  message,
  type: type || 'default',
  time: time || defaultTime,
  start: new Date().getTime(),
  end: new Date().getTime() + (time || defaultTime),
});

export default {
  state: {
    meeseeks: [],
  },
  mutations: {
    addToQueue(state, message) {
      state.meeseeks.push(message);
    },
    removeFromQueue(state, message) {
      state.meeseeks = state.meeseeks.filter((j) => j.end !== message.end);
    },
  },
  actions: {
    meeseeks(commit, { message, type, time }) {
      const msgObj = parseMessage({ message, type, time });
      this.commit('addToQueue', msgObj);
      setTimeout(() => {
        this.commit('removeFromQueue', msgObj);
      }, msgObj.time);
    },
  },
  getters: {
    meeseeks(state) { return state.meeseeks; },
  },
};
