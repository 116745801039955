import Home from '@/components/home/HomePage.vue';

const home = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/:entityslug',
    name: 'entity-page',
    component: () => import('@/components/entities/EntityPage.vue'),
    meta: {
      isPublic: true,
    },
  },
];

export default home;
