export default {
  props: {
    modelValue: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    help: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    runValidations: {
      type: Boolean,
      default: false,
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    validators: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
};
