<template>
  <div class="legendtype">
    <div v-for="(type, j) in entityTypes" :key="j" class="legendtype__item">
      <div class="legendtype__color" :style="{background: entityColor[type]}"></div>
      <div class="legendtype__text">{{$t(`entityType.${type}`)}}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EntityTypesLegend',
  data() {
    return {
      entityColor: {
        GOV: '#f15a24',
        PRIC: '#29abe2',
        EDU: '#fbb03b',
        TER: '#39b54a',
      },
    };
  },
  computed: {
    ...mapGetters(['entityTypes']),
  },
};
</script>

<style lang="scss">
.legendtype {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  &__color {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    vertical-align: top;
    margin: 5px 4px 0 0;
  }
  &__text {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
  }
}
</style>
