module.exports = [
  {
    path: '/report/new',
    name: 'new-report',
    component: () => import('@/components/reports/ReportEditPage.vue'),
    meta: {
      isPublic: false,
      modules: ['reports'],
    },
  },
  {
    path: '/report/:year',
    name: 'report',
    component: () => import('@/components/reports/ReportPage.vue'),
    meta: {
      isPublic: false,
      modules: ['reports'],
    },
  },
  {
    path: '/report/:year/edit',
    name: 'edit-report',
    component: () => import('@/components/reports/ReportEditPage.vue'),
    meta: {
      isPublic: false,
      modules: ['reports'],
    },
  },
  {
    path: '/reports/challenges',
    name: 'report-challenges',
    component: () => import('@/components/challenges/ChallengesPage.vue'),
    meta: {
      isPublic: false,
    },
  },
];
