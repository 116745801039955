export default {
  state: {
    loadProgress: 0,
    loadDone: true,
    loadSucess: true,
  },
  mutations: {
    loadProgress(state, value) {
      state.loadProgress = value;
    },
  },
  actions: {
    loadProgress(commit, progress) {
      this.commit('loadProgress', progress);
    },
  },
  getters: {
    loadProgress(state) { return state.loadProgress; },
  },
};
