<!-- eslint-disable max-len -->
<template>
  <div class="crumb crumb--langs" @click="isActive = !isActive">
    <svg
      class="langimg"
      viewBox="0 0 512 512"
      width="43.028px"
      height="43.028px"
      xmlns="http://www.w3.org/2000/svg">
      <path class="langpath" d="m256 512c140.959 0 256-115.049 256-256 0-140.96-115.049-256-256-256-140.959 0-256 115.049-256 256 0 140.96 115.049 256 256 256zm75.947-43.143c14.505-18.535 25.623-40.643 33.974-62.867h58.966c-24.986 28.11-56.854 49.952-92.94 62.867zm115.476-92.857h-71.804c8.852-32.188 14.045-67.876 15.149-105h90.722c-2.528 38.388-14.68 74.185-34.067 105zm0-240c19.387 30.815 31.539 66.612 34.067 105h-90.722c-1.104-37.124-6.297-72.812-15.149-105zm-22.537-30h-58.966c-8.348-22.205-19.465-44.316-33.974-62.857 36.087 12.915 67.955 34.757 92.94 62.857zm-153.886-73.434c29.436 9.977 50.553 44.985 62.631 73.434h-62.631zm0 103.434h73.427c9.538 31.578 15.149 67.403 16.33 105h-89.757zm0 134.99h89.757c-1.181 37.607-6.792 73.432-16.33 105.01h-73.427zm0 135h62.631c-12.098 28.507-33.219 63.476-62.631 73.444zm-183.886.01h58.966c8.348 22.205 19.465 44.316 33.974 62.857-36.087-12.914-67.955-34.757-92.94-62.857zm153.886 73.434c-29.411-9.968-50.531-44.933-62.631-73.434h62.631zm0-103.434h-73.427c-9.538-31.578-15.149-67.403-16.33-105h89.757zm0-135h-89.757c1.181-37.597 6.792-73.422 16.33-105h73.427zm0-208.434v73.434h-62.631c12.099-28.498 33.218-63.465 62.631-73.434zm-60.947 10.577c-14.505 18.535-25.622 40.642-33.974 62.857h-58.965c24.985-28.1 56.853-49.943 92.939-62.857zm-115.476 92.847h71.804c-8.852 32.198-14.045 67.886-15.149 105.01h-90.722c2.528-38.388 14.68-74.185 34.067-105.01zm-34.067 135.01h90.722c1.104 37.124 6.297 72.812 15.149 105h-71.804c-19.387-30.815-31.539-66.612-34.067-105z"/>
    </svg>

    <div class="crumb__menu" :class="{'is-active': isActive}">
      <ul class="crumb__menu-list">
        <li class="crumb__menu-item" v-for="(locale, l) in locales" :key="l">
          <a :href="`/${locale.code}`">{{$t(`locales.${locale.code}`)}}</a>
        </li>
      </ul>
    </div>

    <teleport to="#app">
      <Overlay v-if="isActive" @close="isActive = false"></Overlay>
    </teleport>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CrumbsLangs',
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    ...mapGetters(['locales']),
  },
};
</script>

<style lang="scss">
.langimg {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(0.8);

  .langpath {
    transition: fill 200ms ease;
    fill: var(--primary-color-strong);
  }

  &:active {
    .langpath {
      opacity: 0.7;
    }
  }
}
</style>
