import * as L from 'leaflet';
import 'leaflet-providers';
import 'leaflet-gesture-handling';
import { select } from 'd3-selection';

const d3 = { select };

export default class {
  constructor(selection, data, config = {}) {
    const self = this;
    this.selection = d3.select(selection);
    this.data = data;
    this.points = [];
    this.events = {
      move: () => {},
      click: () => {},
      dblclick: () => {},
    };

    this.cfg = {
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
      lat: 0,
      lng: 0,
      zoom: 16,
      tileprovider: 'CartoDB.Positron',
    };

    Object.keys(config).forEach((key) => {
      if (config[key] instanceof Object && config[key] instanceof Array === false) {
        Object.keys(config[key]).forEach((sk) => {
          self.cfg[key][sk] = config[key][sk];
        });
      } else self.cfg[key] = config[key];
    });

    this.initGraph();
  }

  initGraph() {
    const self = this;
    this.map = L.map(this.selection.node())
      .setView([self.cfg.lat, self.cfg.lng], self.cfg.zoom);

    this.map.on('moveend', () => {
      this.events.move({
        lat: this.map.getCenter().lat,
        lng: this.map.getCenter().lng,
        zoom: this.map.getZoom(),
      });
    });

    this.map.on('click', (e) => {
      this.events.click({
        ...e.containerPoint,
        ...e.latlng,
      });
    });

    this.map.on('dblclick', (e) => {
      this.events.dblclick({
        ...e.containerPoint,
        ...e.latlng,
      });
    });

    L.tileLayer.provider(self.cfg.tileprovider).addTo(this.map);

    this.svgLayer = L.svg();
    this.svgLayer.addTo(this.map);

    this.svg = this.selection.select('svg');
    this.g = this.svg.append('g');
  }

  fitBounds(zoombounds, options = {}) {
    this.map.fitBounds(zoombounds, options);
  }

  on(listener, fn) {
    this.events[listener] = fn;
  }
}
