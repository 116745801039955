<template>
  <InputBlock
    :label="label"
    :name="name"
    :help="help"
    :isRequired="isRequired"
    :classes="[
      'input-block--checkbox input-block--inline',
      `input-block--name-${name}`,
      ...classes,
    ]">

    <input
      :id="name"
      v-model="value"
      ref="input"
      type="checkbox"
      :required="isRequired"
      :disabled="isDisabled"
      @input="$emit('input', $event.target.value)">

    <div
      class="checkbox"
      :class="{ 'is-active': value }"
      @click="onClick()"></div>

    <template #header>
      <slot name="header"></slot>
    </template>

  </InputBlock>
</template>

<script>
import InputProps from '../InputProps';
import InputMixin from '../InputMixin';
import InputBlock from '../InputBlock.vue';

export default {
  name: 'InputCheckbox',
  mixins: [
    InputMixin,
  ],
  components: {
    InputBlock,
  },
  props: {
    ...InputProps.props,
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue === true;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    onClick() {
      this.value = !this.value;
    },
  },
};
</script>
