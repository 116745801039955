import API from '@/services/api/ApiService';
import HttpService from '@/services/http/HttpService';

export default {
  state: {
    locales: [
      { code: 'es', base: '', translations: 'es.json' },
      { code: 'en', base: '/en', translations: 'en.json' },
    ],
    urlParams: {
      phase: 'phase',
      challenge: 'challenge',
      entityType: 'type',
      entitySector: 'sector',
      entityAdm1: 'adm1',
      entitySDG: 'sdg',
      entityName: 'search',
      entityCategory: 'category',
    },
    adm1: [],
    goals: [],
    indicators: [],
    challenges: [],
    networks: [],
    entityTypes: [],
    entitySectors: [],
    entityLevels: [],
    entityCategories: [],
    socialPlatforms: [],
    phaseColors: ['#e65e63', '#7fdcd9', '#3a7ae6', '#9745ab'],
  },
  mutations: {
    updateUniverseData(state, data) {
      state.adm1 = data.adm1;
      state.goals = data.goals;
      state.indicators = data.indicators;
      state.challenges = data.challenges;
      state.networks = data.networks;
      state.entityTypes = data.entity_types;
      state.entitySectors = data.entity_sectors;
      state.entityLevels = data.entity_levels;
      state.entityCategories = data.entity_categories;
      state.socialPlatforms = data.social_platforms;
    },
  },
  actions: {
    getWorld() {
      return new Promise((resolve, reject) => {
        HttpService.get(API.url('universe'), API.getHeaders())
          .then((response) => {
            this.commit('updateUniverseData', response.data);
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },
  },
  getters: {
    locales: (state) => state.locales,
    urlParams: (state) => state.urlParams,
    adm1: (state) => state.adm1,
    goals: (state) => state.goals,
    indicators: (state) => state.indicators,
    challenges: (state) => state.challenges,
    networks: (state) => state.networks,
    entityTypes: (state) => state.entityTypes,
    entitySectors: (state) => state.entitySectors,
    entityLevels: (state) => state.entityLevels,
    entityCategories: (state) => state.entityCategories,
    socialPlatforms: (state) => state.socialPlatforms,
    phaseColors: (state) => state.phaseColors,
    universeIsLoaded: (state) => state.goals.length > 0,
  },
};
