<template>
  <Chip
    :thumbnail="getThumbnail"
    :letter="getLetter"
    color="#e4e7f3"
    :classes="[...classes, 'chip--challenge']">

    <div class="chip__info chip__info--primary">
      {{$t(`challenges.${challenge.challenge}`)}}
    </div>
    <div class="chip__info chip__info--secondary">
      {{challenge.entity}}
    </div>

  </Chip>
</template>

<script>
import Chip from '@/components/chips/Chip.vue';
import ChipMixin from '@/components/chips/ChipMixin';

export default {
  name: 'ChallengeChip',
  mixins: [
    ChipMixin,
  ],
  components: {
    Chip,
  },
  props: {
    challenge: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getLetter() {
      return 'X';
    },
    getThumbnail() {
      return null;
      // const extnames = this.file.file.split('.');
      // return `/img/icons/filechip/${extnames[extnames.length - 1]}.svg`;
    },
  },
};
</script>

<style lang="scss">
.chip--file {
  .chip__info--primary {
    max-width: initial;
  }
}
</style>
