const ranking = [
  {
    path: '/ranking/:category',
    name: 'ranking',
    component: () => import('@/components/ranking/RankingPage.vue'),
    meta: {
      isPublic: true,
    },
  },
];

export default ranking;
