<template>
  <div class="chartslices">
    <div class="chartslices__wrap" v-if="total > 0">
      <D3SlicesChart
        v-if="!isProcessing"
        :config="chart_config"
        :datum="chartData" height="240"></D3SlicesChart>

      <div v-else class="fake-chart" style="height: 260px"></div>

      <div class="legend">
        <div class="legend__item" v-for="(phase, i) in [1, 2, 3, 4]" :key="phase">
          <div class="legend__name">
            <img :src="`/img/icons/phases/${i}.png`" :alt="$t(`phases.${phase}`)"/>
            {{$t(`phases.${phase}`)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import D3SlicesChart from './assets/sliceschart/d3.sliceschart.vue';

export default {
  name: 'ChartSlices',
  components: {
    D3SlicesChart,
  },
  data() {
    return {
      isProcessing: true,
      chartData: [],
      chart_config: {
        key: 'name',
        value: 'value',
        radius: {
          inner: 50,
          padding: 0.02,
          round: 8,
        },
        color: {
          default: '#cfd8e6',
          key: 'color',
        },
        margin: {
          top: 0,
          bottom: 0,
        },
      },
    };
  },
  props: {
    results: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    total: {
      type: Number,
      required: true,
      default: 1,
    },
    oncreated: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    if (this.oncreated) {
      this.calculeChartData(this.results);
    }
  },
  computed: {
    ...mapGetters(['challenges', 'phaseColors']),
  },
  methods: {
    calculeSliceTotal(results, phase) {
      const phaseChallenges = phase.map((ch) => ch.name);
      return Object.keys(results)
        .map((challenge) => ({ name: challenge, value: results[challenge] }))
        .filter((challenge) => phaseChallenges.indexOf(challenge.name) > -1)
        .reduce((acc, curr) => (acc + curr.value), 0);
    },
    calculeChartData(results) {
      this.isProcessing = true;
      const blocks = this.challenges.filter((ch) => {
        return ch.name !== 'coherence'
        && ch.name !== 'students-sdg'
        && ch.name !== 'sdg-report'
        && ch.name !== 'local-report'
      }).reduce((acc, curr) => {
        if (Object.prototype.hasOwnProperty.call(acc, curr.phase)) acc[curr.phase].push(curr);
        else acc[curr.phase] = [curr];
        return acc;
      }, {});
      this.chartData = [];
      Object.keys(blocks).map((key) => blocks[key]).forEach((phase, i) => {
        this.chartData.push({
          name: this.$t(`phases.${phase[0].phase}`),
          value: this.calculeSliceTotal(results, phase),
          total: this.total * phase.length,
          color: this.phaseColors[i],
        });
      });
      setTimeout(() => {
        this.isProcessing = false;
      }, 100);
    },
  },
  watch: {
    results(results) {
      this.calculeChartData(results);
    },
  },
};
</script>

<style lang="scss" scoped>
.legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  &__item {
    margin-right: 10px;
  }
  &__name {
    display: inline-block;
    text-align: center;
    margin-bottom: 12px;
  }
  img {
    width: 16px;
    height: auto;
    margin-right: 10px;
  }
}
</style>
