import home from './home';
import auth from './auth';
import entities from './entities';
import reports from './reports';
import users from './users';
import help from './help';
import staticpages from './static';
import ranking from './ranking';

export default [
  ...home,
  ...auth,
  ...entities,
  ...reports,
  ...users,
  ...help,
  ...staticpages,
  ...ranking,
];
