<template>
  <button
    type="button"
    :disabled="isDisabled"
    @click="$emit('onclick')"
    class="btn"
    :class="[ ...classes, {
      'btn--loading': isLoading,
    }]">

    <span class="btn__content" :class="{'is-loading': isLoading}">
      <transition name="buttonspinner" mode="out-in">
        <Spinner
          class="btn__loading"
          v-if="isLoading"
          color="white"
          :stroke="6"
          :size="12"></Spinner>
      </transition>
      <slot></slot>
    </span>

  </button>
</template>

<script>
export default {
  name: 'Button',
  emits: ['onclick'],
  data() {
    return {
      loadAnimation: false,
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>
