<template>
  <section class="resran">
    <BlockSubtitle
      modal="ranking">{{$t('common.ranking')}}</BlockSubtitle>

    <transition-group tag="div" class="resran__fields" name="barfade">
      <div v-for="(bar, i) in bars" :key="`${i}`" class="resran__field">
        <div :title="bar.name" class="resran__name">
          <router-link :target="'_blank'" :to="getRoute(bar, $i18n.locale)">
            {{bar.name}}
          </router-link>
        </div>
        <div class="resran__bar" :title="bar.width">
          <div class="resran__backbar"></div>
          <div class="resran__overbars">
            <div
              class="resran__overbar"
              v-for="(phase, j) in bar.phases"
              :key="`${j}`"
              :title="`${phase.name}: ${Math.floor(100*phase.value/phase.total)}%`"
              :style="phase.style">
                <span>{{displayValue(phase)}}</span>
            </div>
          </div>
        </div>
      </div>
    </transition-group>

  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import UrlService from '@/services/url/UrlService';
import BlockSubtitle from '@/components/layout/block-title/BlockSubtitle.vue';

const urlService = new UrlService();

export default {
  name: 'ResultsRankingComponent',
  components: {
    BlockSubtitle,
  },
  data() {
    return {
      bars: [],
    };
  },
  props: {
    results: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  mounted() {
    this.calculeChartData(this.results);
  },
  computed: {
    ...mapGetters(['challenges', 'phaseColors']),
  },
  methods: {
    getRoute(bar, locale) {
      return urlService.entityRoute(bar, locale);
    },
    countEntityPhaseChallenges(entity, phaseChallenges) {
      return phaseChallenges
        .filter((challenge) => entity.last_report?.challenges.findIndex((ch) => ch.challenge === challenge.name) > -1).length;
    },
    // TODO: Abstract challenges filter to all type of entities and apply to stats as well
    calculeChartData(results) {
      this.isProcessing = true;
      const blocks = this.challenges.filter((ch) => {
        return ch.name !== 'coherence'
        && ch.name !== 'students-sdg'
        && ch.name !== 'sdg-report'
        && ch.name !== 'local-report'
      }).reduce((acc, curr) => {
        if (Object.prototype.hasOwnProperty.call(acc, curr.phase)) acc[curr.phase].push(curr);
        else acc[curr.phase] = [curr];
        return acc;
      }, {});
      this.bars = results.map((entity) => {
        const phases = [];
        Object.entries(blocks).forEach(([phase, phaseChallenges], i) => {
          const value = this.countEntityPhaseChallenges(entity, phaseChallenges);
          const total = phaseChallenges.length;
          const percent = Math.floor((value / total) * (100 / Object.keys(blocks).length));
          if (percent > 0) {
            phases.push({
              name: this.$t(`phases.${phase}`),
              value,
              total,
              percent,
              style: {
                'background-color': this.phaseColors[i],
                'flex-basis': `${percent}%`,
              },
            });
          }
        });
        return {
          id: entity.id,
          slug: entity.slug,
          name: entity.acronim,
          phases,
          percent: phases.reduce((acc, o) => (acc + o.percent), 0),
          total: phases.reduce((acc, o) => (acc + o.value), 0),
        };
      }).sort((a, b) => b.percent - a.percent);
    },
    displayValue(phase) {
      const value = Math.floor((100 * phase.value) / phase.total);
      if (value < 15) return '';
      return `${value}%`;
    },
  },
  watch: {
    results(results) {
      this.calculeChartData(results);
    },
  },
};
</script>

<style lang="scss" scoped>
$entity-name-width: 160px;
$light-color: #edf1f7;
$dark-color: #2196f3;
$bar-height: 24px;
$bar-separation: 8px;

.resran {
  &__field {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $bar-separation;
    transition: opacity 500ms ease 250ms;
  }
  &__name {
    flex: 1 1 100%;
    text-align: right;
    margin-right: 20px;
    line-height: $bar-height;
    overflow: hidden;
    a {
      color: $dark-color;
    }
  }
  &__bar {
    flex: 1 1 100%;
    height: $bar-height;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
  }
  &__backbar {
    background: $light-color;
    height: 100%;
    width: 100%;
  }
  &__overbars {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
  }
  &__overbar {
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    transition: flex-basis 350ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
    position: relative;
    overflow: hidden;
    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    span {
      color: white;
      position: absolute;
      top: 6px;
      left: 4px;
      font-size: 12px;
      font-weight: 500;
      pointer-events: none;
      line-height: 1;
    }
  }
}

@media screen and (min-width: 560px) {
  .resran {
    &__field {
      flex-wrap: nowrap;
    }
    &__name {
      flex: 0 0 $entity-name-width;
    }
    &__bar {
      flex: 0 0 calc(100% - #{$entity-name-width});
    }
  }
}
</style>
